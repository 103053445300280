import { render, staticRenderFns } from "./FileLink.vue?vue&type=template&id=e187320e&scoped=true"
import script from "./FileLink.vue?vue&type=script&lang=js"
export * from "./FileLink.vue?vue&type=script&lang=js"
import style0 from "./FileLink.vue?vue&type=style&index=0&id=e187320e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e187320e",
  null
  
)

export default component.exports