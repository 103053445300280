import axios from "axios";

// Replace these with your actual API keys and tokens
const SPOTIFY_ACCESS_TOKEN =
  "BQDBZpZrIXBwCt0o6KMJUnKNctWC00e0BdZOEhQ7yciWJI9Hp807xkmq0NQJppzD7OTG-f587knH6kCNOR583JuVdlb-dPJn3JmMVnP7XjHt5KjTrL6v6WxvJ-6sE1ULx0ORLpwmb609bSp0ufbTntX1vhKVYs2DlYoEsusyOZs3j0WO18oKFVnocwHjRWTnFnfBIyDrjpIibKeUsF156tfS1CntSqZIWeZGaA";
const APPLE_MUSIC_DEVELOPER_TOKEN =
  "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjJYMzM1TTJaWUgifQ.eyJpYXQiOjE3MjU1NDIxNzMsImV4cCI6MTc0MTA5NDE3MywiaXNzIjoiVEhBQkJHRkw3QyJ9.wcZd1UbDTha9GxM0dxfvpNE4u-c1ec-Fc2NXAMXKmhe0kyFtkP74Rx8XkxMRxwyJrzY3xGSQ57yIC9CCfkhFXw";

export async function searchSpotify(query) {
  try {
    const response = await axios.get("https://api.spotify.com/v1/search", {
      params: {
        q: query,
        type: "track",
        limit: 10
      },
      headers: {
        Authorization: `Bearer ${SPOTIFY_ACCESS_TOKEN}`
      }
    });

    return response.data.tracks.items.map(item => ({
      id: item.id,
      title: item.name,
      artist: item.artists[0].name,
      album: item.album.name,
      artwork: item.album.images[0]?.url,
      provider: "spotify",
      isrc: item.external_ids.isrc
    }));
  } catch (error) {
    console.error("Spotify search error:", error);
    return [];
  }
}

export async function searchAppleMusic(query) {
  try {
    const response = await axios.get(
      "https://api.music.apple.com/v1/catalog/us/search",
      {
        params: {
          term: query,
          types: "songs",
          limit: 10
        },
        headers: {
          Authorization: `Bearer ${APPLE_MUSIC_DEVELOPER_TOKEN}`
        }
      }
    );

    return response.data.results.songs.data.map(item => ({
      id: item.id,
      title: item.attributes.name,
      artist: item.attributes.artistName,
      album: item.attributes.albumName,
      artwork: item.attributes.artwork.url.replace("{w}x{h}", "300x300"),
      provider: "apple music",
      provider: "apple music",
      isrc: item.attributes.isrc
    }));
  } catch (error) {
    console.error("Apple Music search error:", error);
    return [];
  }
}

export async function searchDeezer(query) {
  try {
    const response = await axios.get("https://api.deezer.com/2.0/search", {
      params: {
        q: query,
        limit: 10,
        output: "json"
      }
    });

    return response.data.data.map(item => ({
      id: item.id,
      title: item.title,
      artist: item.artist.name,
      album: item.album.title,
      artwork: item.album.cover_big,
      provider: "deezer",
      isrc: item.isrc
    }));
  } catch (error) {
    console.error("Deezer search error:", error);
    return [];
  }
}

export async function searchAllProviders(query) {
  return await Promise.all([
    searchSpotify(query),
    searchAppleMusic(query),
    searchDeezer(query)
  ]);
}
