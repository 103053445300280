<template>
  <div class="px-10 pt-6 overflow-hidden " style="max-height: 100vh">
    <template v-if="spotifyId">
      <!-- <template>
        <Loader size="50" color="#FF035A" />
      </template> -->
      <template>
        <div class="flex items-center justify-between w-full gap-2 pb-5">
          <CatalogFiltration @filter-changed="filterCatalog" />
          <div
            class="flex flex-wrap items-center justify-between flex-1 gap-y-4 gap-x-6"
          >
            <div
              class="max-w-sm px-4 py-2 border-2 border-gray-100 rounded-full search"
            >
              <div class="icon">
                <img src="../assets/icons/Search.svg" />
              </div>
              <input
                type="search"
                v-model="searchQuery"
                placeholder="Search by name"
              />
            </div>
            <div class="flex items-center justify-end gap-4">
              <!-- <CustomSelect
                style="margin-left: 0px"
                default="Sort: A to Z"
                @setValue="(value) => (sortBy = value)"
                :options="[
                  {
                    value: 'az',
                    label: 'Sort: A to Z',
                  },
                  {
                    value: 'za',
                    label: 'Sort: Z to A',
                  },
                  {
                    value: 'newest',
                    label: 'Newest',
                  },
                  {
                    value: 'oldest',
                    label: 'Oldest',
                  },
                ]"
              /> -->
              <CustomSelect
                style="margin-left: 0px; max-width: 300px; min-width: 200px;"
                default="All Albums"
                @setValue="value => (filterByAlbum = value)"
                :options="[
                  {
                    value: 'all',
                    label: 'All Albums'
                  },
                  ...allAlbums
                ]"
                optionsDetached
              />
            </div>
          </div>
          <div>
            <button @click="showModal = 'add-song'" class="primary">
              Protect Unreleased Song
            </button>
          </div>
        </div>
        <template v-if="catalog && catalog.length > 0">
          <div class="relative grid grid-cols-12 gap-10">
            <svg
              v-if="selectedTrack"
              class="absolute top-0 z-50 mt-32"
              style="right: calc(100% - 59.2%);"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0306 12.5312L9.53063 20.0312C9.42573 20.1362 9.29204 20.2078 9.14648 20.2367C9.00092 20.2657 8.85002 20.2509 8.71291 20.1941C8.57579 20.1372 8.45861 20.041 8.37621 19.9176C8.29381 19.7941 8.24988 19.649 8.25 19.5006V4.50059C8.24988 4.35217 8.29381 4.20705 8.37621 4.0836C8.45861 3.96016 8.57579 3.86394 8.71291 3.80712C8.85002 3.75031 9.00092 3.73546 9.14648 3.76444C9.29204 3.79343 9.42573 3.86496 9.53063 3.96997L17.0306 11.47C17.1004 11.5396 17.1557 11.6223 17.1934 11.7134C17.2312 11.8044 17.2506 11.902 17.2506 12.0006C17.2506 12.0992 17.2312 12.1967 17.1934 12.2878C17.1557 12.3788 17.1004 12.4616 17.0306 12.5312Z"
                fill="#343330"
              />
            </svg>
            <div
              class="transition-all duration-300"
              :class="selectedTrack ? 'col-span-7' : 'col-span-12'"
            >
              <div
                class="px-2 overflow-hidden rounded-2xl"
                style="border: 1px solid #e2e2e2"
              >
                <div
                  class="relative flex flex-col w-full gap-6 px-2 pb-4 left-side-bar "
                  :style="'height: calc(100vh - 265px)'"
                >
                  <div class="sticky top-0 w-full bg-white">
                    <div
                      class="grid w-full  gap-4 border-b bg-white border-gray-100 pb-0.5"
                      :class="selectedTrack ? 'grid-cols-3' : 'grid-cols-5'"
                    >
                      <button
                        @click="
                          sortBy === 'az'
                            ? sortCatalog('za')
                            : sortCatalog('az')
                        "
                        class="flex items-center justify-start h-16 col-span-2 icon-sort-container"
                      >
                        <div
                          class="flex items-center justify-start gap-1 text-sm font-medium text-gray-500"
                        >
                          Release
                          <svg
                            class="transition-all duration-300 transform icon-sort"
                            :class="sortBy === 'za' ? 'rotate-180' : ''"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M6 9l6 6l6 -6" />
                          </svg>
                        </div>
                      </button>
                      <button
                        v-if="!selectedTrack"
                        @click="
                          sortBy === 'newest'
                            ? sortCatalog('oldest')
                            : sortCatalog('newest')
                        "
                        class="flex items-center justify-start h-16 icon-sort-container"
                      >
                        <div
                          class="flex items-center justify-start gap-1 text-sm font-medium text-gray-500"
                        >
                          Release Date
                          <svg
                            :class="sortBy === 'oldest' ? 'rotate-180' : ''"
                            class="transition-all duration-300 transform icon-sort"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M6 9l6 6l6 -6" />
                          </svg>
                        </div>
                      </button>
                      <button class="flex items-center justify-start h-16">
                        <div class="text-sm font-medium text-gray-500">
                          Collaborators
                        </div>
                      </button>
                      <button
                        v-if="!selectedTrack"
                        class="flex items-center justify-start h-16"
                      >
                        <div class="text-sm font-medium text-gray-500">
                          Status
                        </div>
                      </button>
                    </div>

                    <div
                      class="grid gap-4 p-1 mt-6 mb-2 transition-all duration-300 border rounded-lg cursor-pointer hover:bg-gray-50"
                      v-if="selectedTrack"
                      :class="[
                        'bg-gray-50  border-gray-100 arrow-after',
                        selectedTrack ? 'grid-cols-3' : 'grid-cols-5'
                      ]"
                    >
                      <div
                        class="flex items-center justify-start col-span-2 gap-5"
                      >
                        <template>
                          <img
                            v-if="selectedTrack.album.images[0]"
                            class="object-cover w-24 h-24 rounded-lg"
                            :src="selectedTrack.album.images[0]"
                          />
                          <img
                            v-else
                            class="w-24 h-24 px-2 py-2 rounded-lg object-fit"
                            style="background-color: #181818;"
                            src="@/assets/default-album.svg"
                          />
                        </template>
                        <div class="flex flex-col flex-1 gap-2">
                          <div class="text-sm font-medium text-gray-800">
                            {{ selectedTrack.name }}
                          </div>
                          <div
                            v-if="!selectedTrack"
                            class="text-sm font-medium text-gray-400"
                          >
                            {{ selectedTrack.album.name }}
                          </div>
                          <div
                            v-if="selectedTrack"
                            class="flex items-center justify-start"
                          >
                            <div
                              v-if="
                                getTrackStatus(selectedTrack) == 'protected'
                              "
                              class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                              style="background-color: #F5FFE4; color: #7AA62D;"
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.8333 9.16797H4.16667C3.24619 9.16797 2.5 9.91416 2.5 10.8346V16.668C2.5 17.5884 3.24619 18.3346 4.16667 18.3346H15.8333C16.7538 18.3346 17.5 17.5884 17.5 16.668V10.8346C17.5 9.91416 16.7538 9.16797 15.8333 9.16797Z"
                                  stroke="#7AA62D"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M5.83203 9.16797V5.83464C5.83203 4.72957 6.27102 3.66976 7.05242 2.88836C7.83382 2.10696 8.89363 1.66797 9.9987 1.66797C11.1038 1.66797 12.1636 2.10696 12.945 2.88836C13.7264 3.66976 14.1654 4.72957 14.1654 5.83464V9.16797"
                                  stroke="#7AA62D"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>

                              Protected
                            </div>
                            <div
                              v-if="getTrackStatus(selectedTrack) == 'waiting'"
                              class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                              style="background-color: #FFF0E0; color: #FF8C00;"
                            >
                              <svg
                                width="14"
                                height="18"
                                viewBox="0 0 14 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.4 1.2C0.4 0.615625 0.88125 0.0999997 1.5 0.0999997H2.6H11.4H12.5C13.0844 0.0999997 13.6 0.615625 13.6 1.2C13.6 1.81875 13.0844 2.3 12.5 2.3V2.67812C12.5 4.15625 11.9156 5.56562 10.8844 6.59687L8.54687 8.9L10.8844 11.2375C11.9156 12.2687 12.5 13.6781 12.5 15.1219V15.5C13.0844 15.5 13.6 16.0156 13.6 16.6C13.6 17.2188 13.0844 17.7 12.5 17.7H11.4H2.6H1.5C0.88125 17.7 0.4 17.2188 0.4 16.6C0.4 16.0156 0.88125 15.5 1.5 15.5V15.1219C1.5 13.6781 2.05 12.2687 3.08125 11.2375L5.41875 8.9L3.08125 6.59687C2.05 5.56562 1.5 4.15625 1.5 2.67812V2.3C0.88125 2.3 0.4 1.81875 0.4 1.2ZM3.7 2.3V2.67812C3.7 3.57187 4.04375 4.39687 4.6625 5.01562L7 7.35312L9.30312 5.01562C9.92187 4.39687 10.3 3.57187 10.3 2.67812V2.3H3.7ZM3.7 15.5H10.3V15.1219C10.3 14.2625 9.92187 13.4375 9.30312 12.8187L7 10.4812L4.6625 12.8187C4.04375 13.4375 3.7 14.2625 3.7 15.1562V15.5344V15.5Z"
                                  fill="#FF8C00"
                                />
                              </svg>

                              Waiting
                            </div>
                            <div
                              v-else-if="
                                getTrackStatus(selectedTrack) == 'action-needed'
                              "
                              class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                              style="background-color: #FFEAE8; color: #D35A4D;"
                            >
                              <svg
                                width="22"
                                height="19"
                                viewBox="0 0 22 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20.6594 15.7594C21.2781 16.8594 20.4875 18.2 19.2156 18.2H2.75C1.47812 18.2 0.687499 16.825 1.30625 15.7594L9.55625 1.425C10.175 0.325 11.7906 0.359375 12.4094 1.425L20.6594 15.7594ZM11 12.7687C10.1062 12.7687 9.41875 13.4906 9.41875 14.35C9.41875 15.2437 10.1062 15.9312 11 15.9312C11.8594 15.9312 12.5812 15.2437 12.5812 14.35C12.5812 13.4906 11.8594 12.7687 11 12.7687ZM9.4875 7.09687L9.72812 11.7719C9.7625 12.0125 9.93437 12.15 10.1406 12.15H11.825C12.0312 12.15 12.2031 12.0125 12.2375 11.7719L12.4781 7.09687C12.5125 6.85625 12.3062 6.65 12.0656 6.65H9.9C9.65937 6.65 9.45312 6.85625 9.4875 7.09687Z"
                                  fill="#A62D2D"
                                />
                              </svg>

                              Action Needed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="!selectedTrack"
                        class="flex items-center justify-start"
                      >
                        <div class="text-sm font-medium text-gray-400">
                          {{
                            formatReleaseDate(selectedTrack.album.release_date)
                          }}
                        </div>
                      </div>
                      <div class="flex items-center justify-start">
                        <div class="text-sm font-medium text-gray-400">
                          {{
                            selectedTrack.artists
                              .slice(0, 2)
                              .map(artist => artist.name)
                              .join(", ")
                          }}
                          <span
                            v-if="
                              selectedTrack.artists &&
                                selectedTrack.artists.length > 2
                            "
                            class="px-2 py-1 ml-1 text-xs text-gray-600 bg-gray-200 rounded-full"
                          >
                            +{{ selectedTrack.artists.length - 2 }}
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="!selectedTrack"
                        class="flex items-center justify-start"
                      >
                        <div
                          v-if="getTrackStatus(selectedTrack) == 'protected'"
                          class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                          style="background-color: #F5FFE4; color: #7AA62D;"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.8333 9.16797H4.16667C3.24619 9.16797 2.5 9.91416 2.5 10.8346V16.668C2.5 17.5884 3.24619 18.3346 4.16667 18.3346H15.8333C16.7538 18.3346 17.5 17.5884 17.5 16.668V10.8346C17.5 9.91416 16.7538 9.16797 15.8333 9.16797Z"
                              stroke="#7AA62D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.83203 9.16797V5.83464C5.83203 4.72957 6.27102 3.66976 7.05242 2.88836C7.83382 2.10696 8.89363 1.66797 9.9987 1.66797C11.1038 1.66797 12.1636 2.10696 12.945 2.88836C13.7264 3.66976 14.1654 4.72957 14.1654 5.83464V9.16797"
                              stroke="#7AA62D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          Protected
                        </div>
                        <div
                          v-if="getTrackStatus(selectedTrack) == 'waiting'"
                          class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                          style="background-color: #FFF0E0; color: #FF8C00;"
                        >
                          <svg
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.4 1.2C0.4 0.615625 0.88125 0.0999997 1.5 0.0999997H2.6H11.4H12.5C13.0844 0.0999997 13.6 0.615625 13.6 1.2C13.6 1.81875 13.0844 2.3 12.5 2.3V2.67812C12.5 4.15625 11.9156 5.56562 10.8844 6.59687L8.54687 8.9L10.8844 11.2375C11.9156 12.2687 12.5 13.6781 12.5 15.1219V15.5C13.0844 15.5 13.6 16.0156 13.6 16.6C13.6 17.2188 13.0844 17.7 12.5 17.7H11.4H2.6H1.5C0.88125 17.7 0.4 17.2188 0.4 16.6C0.4 16.0156 0.88125 15.5 1.5 15.5V15.1219C1.5 13.6781 2.05 12.2687 3.08125 11.2375L5.41875 8.9L3.08125 6.59687C2.05 5.56562 1.5 4.15625 1.5 2.67812V2.3C0.88125 2.3 0.4 1.81875 0.4 1.2ZM3.7 2.3V2.67812C3.7 3.57187 4.04375 4.39687 4.6625 5.01562L7 7.35312L9.30312 5.01562C9.92187 4.39687 10.3 3.57187 10.3 2.67812V2.3H3.7ZM3.7 15.5H10.3V15.1219C10.3 14.2625 9.92187 13.4375 9.30312 12.8187L7 10.4812L4.6625 12.8187C4.04375 13.4375 3.7 14.2625 3.7 15.1562V15.5344V15.5Z"
                              fill="#FF8C00"
                            />
                          </svg>

                          Waiting
                        </div>
                        <div
                          v-else-if="
                            getTrackStatus(selectedTrack) == 'action-needed'
                          "
                          class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                          style="background-color: #FFEAE8; color: #D35A4D;"
                        >
                          <svg
                            width="22"
                            height="19"
                            viewBox="0 0 22 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.6594 15.7594C21.2781 16.8594 20.4875 18.2 19.2156 18.2H2.75C1.47812 18.2 0.687499 16.825 1.30625 15.7594L9.55625 1.425C10.175 0.325 11.7906 0.359375 12.4094 1.425L20.6594 15.7594ZM11 12.7687C10.1062 12.7687 9.41875 13.4906 9.41875 14.35C9.41875 15.2437 10.1062 15.9312 11 15.9312C11.8594 15.9312 12.5812 15.2437 12.5812 14.35C12.5812 13.4906 11.8594 12.7687 11 12.7687ZM9.4875 7.09687L9.72812 11.7719C9.7625 12.0125 9.93437 12.15 10.1406 12.15H11.825C12.0312 12.15 12.2031 12.0125 12.2375 11.7719L12.4781 7.09687C12.5125 6.85625 12.3062 6.65 12.0656 6.65H9.9C9.65937 6.65 9.45312 6.85625 9.4875 7.09687Z"
                              fill="#A62D2D"
                            />
                          </svg>

                          Action Needed
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col gap-4">
                    <div
                      class="grid gap-4 p-1 transition-all duration-300 border rounded-lg cursor-pointer hover:bg-gray-50"
                      :class="[
                        selectedTrack === track
                          ? 'hidden'
                          : 'bg-transparent border-transparent',
                        selectedTrack ? 'grid-cols-3' : 'grid-cols-5'
                      ]"
                      v-for="track in filteredCatalog"
                      :key="track.id"
                      @click="selectSong(track.id)"
                      :id="track.id"
                    >
                      <div
                        class="flex items-center justify-start col-span-2 gap-5"
                      >
                        <template>
                          <img
                            v-if="track.album.images[0]"
                            class="object-cover w-24 h-24 rounded-lg"
                            :src="track.album.images[0]"
                          />
                          <img
                            v-else
                            class="w-24 h-24 px-2 py-2 rounded-lg object-fit"
                            style="background-color: #181818;"
                            src="@/assets/default-album.svg"
                          />
                        </template>
                        <div class="flex flex-col flex-1 gap-2">
                          <div class="text-sm font-medium text-gray-800">
                            {{ track.name }}
                          </div>
                          <div
                            v-if="!selectedTrack"
                            class="text-sm font-medium text-gray-400"
                          >
                            {{ track.album.name }}
                          </div>
                          <div
                            v-if="selectedTrack"
                            class="flex items-center justify-start"
                          >
                            <div
                              v-if="getTrackStatus(track) == 'protected'"
                              class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                              style="background-color: #F5FFE4; color: #7AA62D;"
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.8333 9.16797H4.16667C3.24619 9.16797 2.5 9.91416 2.5 10.8346V16.668C2.5 17.5884 3.24619 18.3346 4.16667 18.3346H15.8333C16.7538 18.3346 17.5 17.5884 17.5 16.668V10.8346C17.5 9.91416 16.7538 9.16797 15.8333 9.16797Z"
                                  stroke="#7AA62D"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M5.83203 9.16797V5.83464C5.83203 4.72957 6.27102 3.66976 7.05242 2.88836C7.83382 2.10696 8.89363 1.66797 9.9987 1.66797C11.1038 1.66797 12.1636 2.10696 12.945 2.88836C13.7264 3.66976 14.1654 4.72957 14.1654 5.83464V9.16797"
                                  stroke="#7AA62D"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>

                              Protected
                            </div>
                            <div
                              v-if="getTrackStatus(track) == 'waiting'"
                              class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                              style="background-color: #FFF0E0; color: #FF8C00;"
                            >
                              <svg
                                width="14"
                                height="18"
                                viewBox="0 0 14 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.4 1.2C0.4 0.615625 0.88125 0.0999997 1.5 0.0999997H2.6H11.4H12.5C13.0844 0.0999997 13.6 0.615625 13.6 1.2C13.6 1.81875 13.0844 2.3 12.5 2.3V2.67812C12.5 4.15625 11.9156 5.56562 10.8844 6.59687L8.54687 8.9L10.8844 11.2375C11.9156 12.2687 12.5 13.6781 12.5 15.1219V15.5C13.0844 15.5 13.6 16.0156 13.6 16.6C13.6 17.2188 13.0844 17.7 12.5 17.7H11.4H2.6H1.5C0.88125 17.7 0.4 17.2188 0.4 16.6C0.4 16.0156 0.88125 15.5 1.5 15.5V15.1219C1.5 13.6781 2.05 12.2687 3.08125 11.2375L5.41875 8.9L3.08125 6.59687C2.05 5.56562 1.5 4.15625 1.5 2.67812V2.3C0.88125 2.3 0.4 1.81875 0.4 1.2ZM3.7 2.3V2.67812C3.7 3.57187 4.04375 4.39687 4.6625 5.01562L7 7.35312L9.30312 5.01562C9.92187 4.39687 10.3 3.57187 10.3 2.67812V2.3H3.7ZM3.7 15.5H10.3V15.1219C10.3 14.2625 9.92187 13.4375 9.30312 12.8187L7 10.4812L4.6625 12.8187C4.04375 13.4375 3.7 14.2625 3.7 15.1562V15.5344V15.5Z"
                                  fill="#FF8C00"
                                />
                              </svg>

                              Waiting
                            </div>
                            <div
                              v-else-if="
                                getTrackStatus(track) == 'action-needed'
                              "
                              class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                              style="background-color: #FFEAE8; color: #D35A4D;"
                            >
                              <svg
                                width="22"
                                height="19"
                                viewBox="0 0 22 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20.6594 15.7594C21.2781 16.8594 20.4875 18.2 19.2156 18.2H2.75C1.47812 18.2 0.687499 16.825 1.30625 15.7594L9.55625 1.425C10.175 0.325 11.7906 0.359375 12.4094 1.425L20.6594 15.7594ZM11 12.7687C10.1062 12.7687 9.41875 13.4906 9.41875 14.35C9.41875 15.2437 10.1062 15.9312 11 15.9312C11.8594 15.9312 12.5812 15.2437 12.5812 14.35C12.5812 13.4906 11.8594 12.7687 11 12.7687ZM9.4875 7.09687L9.72812 11.7719C9.7625 12.0125 9.93437 12.15 10.1406 12.15H11.825C12.0312 12.15 12.2031 12.0125 12.2375 11.7719L12.4781 7.09687C12.5125 6.85625 12.3062 6.65 12.0656 6.65H9.9C9.65937 6.65 9.45312 6.85625 9.4875 7.09687Z"
                                  fill="#A62D2D"
                                />
                              </svg>

                              Action Needed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="!selectedTrack"
                        class="flex items-center justify-start"
                      >
                        <div class="text-sm font-medium text-gray-400">
                          {{ formatReleaseDate(track.album.release_date) }}
                        </div>
                      </div>
                      <div class="flex items-center justify-start">
                        <div class="text-sm font-medium text-gray-400">
                          {{
                            track.artists
                              .slice(0, 2)
                              .map(artist => artist.name)
                              .join(", ")
                          }}
                          <span
                            v-if="track.artists && track.artists.length > 2"
                            class="px-2 py-1 ml-1 text-xs text-gray-600 bg-gray-200 rounded-full"
                          >
                            +{{ track.artists.length - 2 }}
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="!selectedTrack"
                        class="flex items-center justify-start"
                      >
                        <div
                          v-if="getTrackStatus(track) == 'protected'"
                          class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                          style="background-color: #F5FFE4; color: #7AA62D;"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.8333 9.16797H4.16667C3.24619 9.16797 2.5 9.91416 2.5 10.8346V16.668C2.5 17.5884 3.24619 18.3346 4.16667 18.3346H15.8333C16.7538 18.3346 17.5 17.5884 17.5 16.668V10.8346C17.5 9.91416 16.7538 9.16797 15.8333 9.16797Z"
                              stroke="#7AA62D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.83203 9.16797V5.83464C5.83203 4.72957 6.27102 3.66976 7.05242 2.88836C7.83382 2.10696 8.89363 1.66797 9.9987 1.66797C11.1038 1.66797 12.1636 2.10696 12.945 2.88836C13.7264 3.66976 14.1654 4.72957 14.1654 5.83464V9.16797"
                              stroke="#7AA62D"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          Protected
                        </div>
                        <div
                          v-if="getTrackStatus(track) == 'waiting'"
                          class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                          style="background-color: #FFF0E0; color: #FF8C00;"
                        >
                          <svg
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.4 1.2C0.4 0.615625 0.88125 0.0999997 1.5 0.0999997H2.6H11.4H12.5C13.0844 0.0999997 13.6 0.615625 13.6 1.2C13.6 1.81875 13.0844 2.3 12.5 2.3V2.67812C12.5 4.15625 11.9156 5.56562 10.8844 6.59687L8.54687 8.9L10.8844 11.2375C11.9156 12.2687 12.5 13.6781 12.5 15.1219V15.5C13.0844 15.5 13.6 16.0156 13.6 16.6C13.6 17.2188 13.0844 17.7 12.5 17.7H11.4H2.6H1.5C0.88125 17.7 0.4 17.2188 0.4 16.6C0.4 16.0156 0.88125 15.5 1.5 15.5V15.1219C1.5 13.6781 2.05 12.2687 3.08125 11.2375L5.41875 8.9L3.08125 6.59687C2.05 5.56562 1.5 4.15625 1.5 2.67812V2.3C0.88125 2.3 0.4 1.81875 0.4 1.2ZM3.7 2.3V2.67812C3.7 3.57187 4.04375 4.39687 4.6625 5.01562L7 7.35312L9.30312 5.01562C9.92187 4.39687 10.3 3.57187 10.3 2.67812V2.3H3.7ZM3.7 15.5H10.3V15.1219C10.3 14.2625 9.92187 13.4375 9.30312 12.8187L7 10.4812L4.6625 12.8187C4.04375 13.4375 3.7 14.2625 3.7 15.1562V15.5344V15.5Z"
                              fill="#FF8C00"
                            />
                          </svg>

                          Waiting
                        </div>
                        <div
                          v-else-if="getTrackStatus(track) == 'action-needed'"
                          class="flex items-center justify-center gap-1 px-3 py-2 text-xs font-semibold rounded-lg cursor-default select-none"
                          style="background-color: #FFEAE8; color: #D35A4D;"
                        >
                          <svg
                            width="22"
                            height="19"
                            viewBox="0 0 22 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.6594 15.7594C21.2781 16.8594 20.4875 18.2 19.2156 18.2H2.75C1.47812 18.2 0.687499 16.825 1.30625 15.7594L9.55625 1.425C10.175 0.325 11.7906 0.359375 12.4094 1.425L20.6594 15.7594ZM11 12.7687C10.1062 12.7687 9.41875 13.4906 9.41875 14.35C9.41875 15.2437 10.1062 15.9312 11 15.9312C11.8594 15.9312 12.5812 15.2437 12.5812 14.35C12.5812 13.4906 11.8594 12.7687 11 12.7687ZM9.4875 7.09687L9.72812 11.7719C9.7625 12.0125 9.93437 12.15 10.1406 12.15H11.825C12.0312 12.15 12.2031 12.0125 12.2375 11.7719L12.4781 7.09687C12.5125 6.85625 12.3062 6.65 12.0656 6.65H9.9C9.65937 6.65 9.45312 6.85625 9.4875 7.09687Z"
                              fill="#A62D2D"
                            />
                          </svg>

                          Action Needed
                        </div>
                      </div>
                    </div>
                    <div v-if="filteredCatalog.length === 0">
                      <div class="text-sm font-medium text-gray-400">
                        No tracks found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="selectedTrack"
              :class="selectedTrack ? 'col-span-5' : 'col-span-0'"
              class="overflow-hidden"
              :style="'height: calc(100vh - 265px)'"
            >
              <SongPreview
                :songs="songs"
                @refetchSongs="
                  () => {
                    $apollo.queries.results.refetch();
                    $apollo.queries.catalog.refetch();
                  }
                "
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-xl font-medium text-gray-800">Catalog</div>
          <div class="mt-3 text-sm font-medium text-gray-400">
            You don't have any tracks in your catalog yet, you can import them
            from your spotify account.
            <button
              class="ml-10 primary"
              :disabled="importLoading"
              @click="importCatalog"
            >
              {{ importLoading ? "Importing..." : "Import" }}
            </button>
          </div>
          <div v-if="importLoading" class="mt-3 text-sm font-medium text-nvoko">
            Importing your tracks may take some time, please be patient.
          </div>
        </template>
      </template>
    </template>
    <template v-else>
      <div class="text-sm font-medium text-gray-500">
        You need to asociate a spotify account to view your catalog
      </div>
    </template>
    <AddSong
      :my-artist="myArtist"
      add-new-required
      v-if="showModal === 'add-song'"
      @close="showModal = ''"
      :addUnreleasedSong="true"
    />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import GET_ARTIST_CATALOG from "@/api/queries/GET_ARTIST_CATALOG.gql";
import IMPORT_CATALOG from "@/api/mutations/IMPORT_CATALOG.gql";
import CustomSelect from "@/components/Select.vue";
import SongPreview from "@/components/RevampedSongPreview.vue";
import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import CatalogFiltration from "../components/CatalogFiltration.vue";
import AddSong from "@/components/modals/AddSong";
export default {
  components: {
    Loader,
    CustomSelect,
    SongPreview,
    CatalogFiltration,
    AddSong
  },
  data() {
    return {
      initialLoad: false,
      importLoading: false,
      searchQuery: "",
      spotifyId: this.$store.getters["account/getArtist"].spotify_id,
      selectedTrack: null,
      song: null,
      sortBy: "az",
      filterByAlbum: "",
      songs: [],
      myArtist: this.$store.getters["account/getArtist"],
      filter: "all",
      showModal: ""
    };
  },
  apollo: {
    catalog: {
      query: GET_ARTIST_CATALOG,
      variables() {
        return {
          artistId: this.$store.getters["account/getArtist"].spotify_id
        };
      },
      update(data) {
        return data.imported_tracks;
      }
    },
    results: {
      query: GET_SONGS,
      variables() {
        return { artistId: this.$store.getters["account/getArtistId"] };
      },
      update({ artists, songs }) {
        console.log({ songs });
        return {
          artists,
          songs: this.formatSongs(songs)
        };
      }
    }
  },
  methods: {
    sortCatalog(sortBy) {
      this.sortBy = sortBy;
    },
    filterCatalog(filter) {
      this.filter = filter;
    },
    getTrackStatus(track) {
      // return "waiting";
      const song = this.songs.find(s => s.spotify_id === track.spotify_id);
      if (song) {
        if (song.collaborators.length === 1) {
          return "action-needed";
        } else {
          return "waiting";
        }
        const collaborator = song.collaborators.find(c => c.isMe);
        if (collaborator) {
          return collaborator.category;
        }
      }

      return "action-needed";

      // Fallback to the original logic if no matching song is found
      if (track.contracts?.length > 0) {
        return "protected";
      } else if (track.contracts?.length === 0) {
        return "waiting";
      }
      return "action-needed";
    },
    formatReleaseDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    async importCatalog() {
      this.importLoading = true;
      await this.$apollo.mutate({
        mutation: IMPORT_CATALOG,
        variables: {
          artistId: this.spotifyId,
          nvokoArtistId: this.$store.getters["account/getArtistId"]
        }
      });
      this.importLoading = false;
      this.$apollo.queries.catalog.refetch();
      this.$apollo.queries.results.refetch();
    },
    selectSong(id) {
      this.selectedTrack = this.combinedCatalog.find(track => track.id === id);
      if (!this.selectedTrack) return;
      this.song = this.songs.find(
        song =>
          (this.selectedTrack.spotify_id &&
            song.spotify_id === this.selectedTrack.spotify_id) ||
          (!this.selectedTrack.spotify_id && song.id === this.selectedTrack.id)
      );
      // this.$nextTick(() => {
      //   const sidebarElement = this.$el.querySelector(".left-side-bar");
      //   const selectedSongElement = document.getElementById(id);
      //   if (sidebarElement && selectedSongElement) {
      //     sidebarElement.scrollTop = selectedSongElement.offsetTop - 80;
      //   }
      // });

      if (
        this.$route.name !== "Catalog" ||
        this.$route.query.song !== this.song.id
      ) {
        this.$router.push({ name: "Catalog", query: { song: this.song.id } });
      }
    },
    formatSongs(songs) {
      console.log({ originSongs: songs });
      return songs.map(
        ({
          id,
          name,
          splits,
          host: hostArtist,
          purchases,
          is_host_company,
          contacts,
          payment_done_off_platform,
          history,
          spotify_id,
          imported_track_id,
          created_at,
          updated_at,
          external_releases
        }) => {
          let host = {
            info: this.formatArtist(hostArtist),
            isHost: true,
            songHost: true,
            isMe: this.isMe(hostArtist),
            hostActive: this.isMe(hostArtist),
            category: null
          };

          let isPartyCompany = null;
          let myDeal = null;

          if (this.isMe(hostArtist)) isPartyCompany = is_host_company;

          const details = { id, name, purchased: !!purchases?.length };
          const { split_details = [] } = splits[0] || {};

          const guests = split_details.map(el => {
            const artist =
              el.deal.artistFrom.id === hostArtist.id
                ? el.deal.artistTo
                : el.deal.artistFrom;

            if (this.isMe(artist)) {
              isPartyCompany = el.deal.is_guest_company;
              myDeal = el.deal.id;
            }

            return {
              status: el.deal.status,
              info: this.formatArtist(artist),
              services: el.deal.services || [],
              fee_status: el.deal.fee_status,
              fee: el.deal.fee,
              percentage: el.percentage,
              deal_id: el.deal_id,
              isMe: this.isMe(artist),
              category: this.getCategory(
                this.isMe(hostArtist),
                this.isMe(artist),
                el.deal.status
              ),
              hostActive: this.isMe(el.deal.artistFrom),
              songHost: false,
              chargesEnabled: this.checkChargesEnabled(artist),
              inducement: this.getInducementLetter(
                el.deal.contracts[0],
                this.isMe(el.deal.artistFrom) ? "host" : "guest",
                {
                  host: el.deal.artistFrom.artist_users[0]?.user_id,
                  guest: el.deal.artistTo.artist_users[0]?.user_id
                },
                {
                  host: el.deal.artistFrom.name,
                  guest: el.deal.artistTo.name
                },
                isPartyCompany
              ),
              recoupableFee: this.getRecoupableFee(el.deal.contracts[0] || {})
            };
          });

          const paid_by = purchases?.length
            ? { ...purchases[0].paid_by, paid_at: purchases[0].created_at }
            : null;

          host.percentage = this.getHostPercentage(guests);
          return {
            ...details,
            isPartyCompany,
            myDeal,
            host_id: hostArtist.id,
            collaborators: [host, ...guests],
            ...this.getViewerInfo([host, ...guests]),
            contacts,
            paid_by,
            payment_done_off_platform,
            history,
            spotify_id,
            imported_track_id,
            created_at,
            updated_at,
            external_releases
          };
        }
      );
    },
    isMe(artist) {
      return artist.id === this.$store.getters["account/getArtistId"];
    },
    formatArtist(artist) {
      if (artist.name) return artist;
      let name = artist.artist_users[0]?.user.email;
      return { ...artist, name };
    },
    getCategory(isHost, isGuest, status) {
      if (!isHost && !isGuest) return "null";

      let myParty = isHost ? "host" : "guest";
      let otherParty = isHost ? "guest" : "host";

      if (status.includes(`-${myParty}`)) return "pending";
      if (status.includes(`-${otherParty}`) || status === "pending")
        return "action-needed";

      if (status === "contract-ready") return "completed";
      return "pending";
    },
    checkChargesEnabled(artist) {
      return !!((artist.artist_users || [])[0]?.user || {}).charges_enabled;
    },
    getInducementLetter(
      contract,
      viewer,
      artistUser,
      artistNames,
      isPartyCompany
    ) {
      if (!contract) return { applicable: false };

      const inducementSignature = contract.signatures.find(
        ({ party, type }) => party === viewer && type === "inducement"
      );

      const applicable = !!isPartyCompany;
      const isArtist = artistUser[viewer] === this.user?.id;

      let status;
      if (inducementSignature) {
        status = "Completed &#9989;";
      } else if (isArtist) {
        status = "Waiting on <b>You</b>";
      } else {
        status = `Waiting on <b>${artistNames[viewer]}</b>`;
      }

      return {
        contract_id: contract.id,
        contract_text: contract[`generated_${viewer}_inducement`],
        contract_url: contract[`${viewer}_inducement_url`],
        signature: inducementSignature,
        applicable,
        canSign:
          applicable &&
          artistUser[viewer] === this.user?.id &&
          !inducementSignature,
        status
      };
    },
    getRecoupableFee(contract) {
      const recoupableFeeQ = contract.contract_answers?.find(
        ({ question_id }) =>
          question_id === "434b07dc-0218-49c1-9ce1-e1101c93a239"
      );
      return recoupableFeeQ?.answer.value;
    },
    getHostPercentage(guests = []) {
      let resp = {
        recording: 100,
        publishing: 100
      };
      guests.forEach(guest => {
        Object.keys(guest.percentage).forEach(el => {
          if (resp[el]) resp[el] -= guest.percentage[el];
        });
      });
      return resp;
    },
    getViewerInfo(collaborators) {
      const { recording, publishing } = collaborators.find(
        el => el.isMe
      ).percentage;
      return {
        isHost: collaborators[0].isMe,
        myRPM: recording + "% Rec, " + publishing + "% Pub"
      };
    }
  },
  computed: {
    filteredCatalog() {
      let filtered = this.combinedCatalog;

      // Apply search filter
      if (this.searchQuery) {
        const searchQueryLower = this.searchQuery.toLowerCase();
        filtered = filtered.filter(track => {
          return (
            track.name.toLowerCase().includes(searchQueryLower) ||
            track.artists.some(artist =>
              artist.name.toLowerCase().includes(searchQueryLower)
            ) ||
            track.album.name.toLowerCase().includes(searchQueryLower)
          );
        });
      }

      // Apply album filter
      if (this.filterByAlbum && this.filterByAlbum !== "all") {
        filtered = filtered.filter(
          track => track.album.name === this.filterByAlbum
        );
      }

      // Apply status filter using getTrackStatus
      if (this.filter !== "all") {
        filtered = filtered.filter(track => {
          const status = this.getTrackStatus(track);
          return status === this.filter;
        });
      }

      // Apply sorting
      filtered.sort((a, b) => {
        if (this.sortBy === "az") {
          return a.name.localeCompare(b.name);
        } else if (this.sortBy === "za") {
          return b.name.localeCompare(a.name);
        }
        if (this.sortBy === "newest") {
          return (
            new Date(b.album.release_date) - new Date(a.album.release_date)
          );
        }
        if (this.sortBy === "oldest") {
          return (
            new Date(a.album.release_date) - new Date(b.album.release_date)
          );
        }
        return 0; // Default case, no sorting
      });

      return filtered;
    },
    allAlbums() {
      return [...new Set(this.combinedCatalog.map(el => el.album.name))].map(
        albumName => ({
          label: albumName,
          value: albumName,
          image: this.combinedCatalog.find(el => el.album.name === albumName)
            .album.images[0]
        })
      );
    },
    foundSong() {
      const { song } = this.$route.query;
      if (!song) return {};
      return this.songs.find(el => el.id === song);
    },
    combinedCatalog() {
      const combined = [];
      if (this.catalog && this.catalog?.length > 0) {
        combined.push(...this.catalog);
      }
      if (this.songs && this.songs?.length > 0) {
        this.songs.forEach(song => {
          if (
            !combined.find(
              el => el.spotify_id === song.spotify_id && el.spotify_id
            )
          ) {
            combined.push({
              id: song.id,
              spotify_id: song.spotify_id,
              name: song.name,
              preview_url: song.preview_url,
              created_at: song.created_at,
              updated_at: song.updated_at,
              album: {
                id: song.album_id,
                name: "",
                spotify_album_id: "",
                images: [null],
                release_date: song.created_at
              },
              artists: [],
              contracts: [
                {
                  id: song.id,
                  name: song.name
                }
              ]
            });
          }
        });
      }

      // Filter out duplicate spotify_ids, keeping items with null spotify_ids
      const uniqueCombined = combined.reduce((acc, current) => {
        const x = acc.find(
          item =>
            item.spotify_id === current.spotify_id && item.spotify_id !== null
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      return uniqueCombined;
    }
  },
  watch: {
    results({ songs }) {
      this.songs = songs;
      this.myArtist = this.$store.getters["account/getArtist"];
    },
    catalog() {},
    foundSong() {
      if (!this.foundSong || Object.keys(this.foundSong)?.length === 0)
        this.selectedTrack = null;
    },

    combinedCatalog: {
      immediate: true,
      handler(newCatalog) {
        this.$nextTick(() => {
          const { song: songId } = this.$route.query;
          if (songId) {
            this.selectSong(songId);
          }
        });
        console.log({ combined: this.combinedCatalog });
      }
    }
  },
  mounted() {
    if (this.combinedCatalog?.length > 0) {
      if (!this.selectedTrack) {
        const { song: songId } = this.$route.query;
        if (!songId) return {};

        const song = this.songs.find(el => el.id === songId);

        if (!song) return;

        this.selectedTrack = this.combinedCatalog.find(
          track => track.spotify_id === song.spotify_id
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.vue-popover {
  width: fit-content !important;
}

.left-side-bar {
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: $accent rgba(#cdcccc, 0.3);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    @include background-opacity(#cdcccc, 0.3);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent;
    border-radius: 5px;
  }
}

.icon-sort {
  opacity: 0;
}

.icon-sort-container:hover {
  .icon-sort {
    opacity: 1;
  }
}
</style>
