<template>
  <div class="right-panel">
    <div v-if="!Object.keys(song || {}).length" />
    <div v-else class="panel-container">
      <div class="panel-header">
        <span class="flex items-center justify-start gap-3">
          <span class="flex items-center justify-start gap-2">
            <span v-if="!isEditingName" @dblclick="editSongName">{{
              song.name
            }}</span>
            <input
              v-else
              v-model="tempSongName"
              @blur="saveSongName"
              @keyup.enter="saveSongName"
            />

            <button
              class="p-2"
              @click="isEditingName ? saveSongName() : editSongName()"
            >
              <svg
                v-if="!isEditingName"
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-edit"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                />
                <path
                  d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                />
                <path d="M16 5l3 3" />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-check"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </button>
          </span>

          <span
            v-if="song.purchased"
            @click="openPaymentDropin"
            class="inline-flex items-center gap-2 px-2 py-1 mt-2 text-xs font-medium text-green-600 rounded-md cursor-pointer bg-green-50 ring-1 ring-inset ring-green-200"
          >
            <svg
              class="w-5 h-5 text-green-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Paid
          </span>

          <span
            v-if="
              song.payment_done_off_platform == null &&
                songStatus == 'contract-ready'
            "
            @click="openPaymentDoneOffPlatformModal"
            class="inline-flex items-center gap-2 px-2 py-1 mt-2 text-xs font-medium text-yellow-600 rounded-md cursor-pointer bg-yellow-50 ring-1 ring-inset ring-yellow-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-yellow-400"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M14.897 1a4 4 0 0 1 2.664 1.016l.165 .156l4.1 4.1a4 4 0 0 1 1.168 2.605l.006 .227v5.794a4 4 0 0 1 -1.016 2.664l-.156 .165l-4.1 4.1a4 4 0 0 1 -2.603 1.168l-.227 .006h-5.795a3.999 3.999 0 0 1 -2.664 -1.017l-.165 -.156l-4.1 -4.1a4 4 0 0 1 -1.168 -2.604l-.006 -.227v-5.794a4 4 0 0 1 1.016 -2.664l.156 -.165l4.1 -4.1a4 4 0 0 1 2.605 -1.168l.227 -.006h5.793zm-2.897 14a1 1 0 0 0 -.993 .883l-.007 .117l.007 .127a1 1 0 0 0 1.986 0l.007 -.117l-.007 -.127a1 1 0 0 0 -.993 -.883zm1.368 -6.673a2.98 2.98 0 0 0 -3.631 .728a1 1 0 0 0 1.44 1.383l.171 -.18a.98 .98 0 0 1 1.11 -.15a1 1 0 0 1 -.34 1.886l-.232 .012a1 1 0 0 0 .111 1.994a3 3 0 0 0 1.371 -5.673z"
                stroke-width="0"
                fill="currentColor"
              />
            </svg>

            Was the payment done off platform?
          </span>

          <span
            v-if="song.payment_done_off_platform === true"
            class="inline-flex items-center gap-2 px-2 py-1 mt-2 text-xs font-medium rounded-md cursor-default text-nvoko bg-red-50 ring-1 ring-inset ring-red-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-red-400"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M14.897 1a4 4 0 0 1 2.664 1.016l.165 .156l4.1 4.1a4 4 0 0 1 1.168 2.605l.006 .227v5.794a4 4 0 0 1 -1.016 2.664l-.156 .165l-4.1 4.1a4 4 0 0 1 -2.603 1.168l-.227 .006h-5.795a3.999 3.999 0 0 1 -2.664 -1.017l-.165 -.156l-4.1 -4.1a4 4 0 0 1 -1.168 -2.604l-.006 -.227v-5.794a4 4 0 0 1 1.016 -2.664l.156 -.165l4.1 -4.1a4 4 0 0 1 2.605 -1.168l.227 -.006h5.793zm-2.897 14a1 1 0 0 0 -.993 .883l-.007 .117l.007 .127a1 1 0 0 0 1.986 0l.007 -.117l-.007 -.127a1 1 0 0 0 -.993 -.883zm1.368 -6.673a2.98 2.98 0 0 0 -3.631 .728a1 1 0 0 0 1.44 1.383l.171 -.18a.98 .98 0 0 1 1.11 -.15a1 1 0 0 1 -.34 1.886l-.232 .012a1 1 0 0 0 .111 1.994a3 3 0 0 0 1.371 -5.673z"
                stroke-width="0"
                fill="currentColor"
              />
            </svg>

            Payment not secured by Nvoko
          </span>

          <span
            v-if="song.payment_done_off_platform === false"
            class="inline-flex items-center gap-2 px-2 py-1 mt-2 text-xs font-medium text-green-600 rounded-md cursor-default bg-green-50 ring-1 ring-inset ring-green-200"
          >
            <svg
              class="w-5 h-5 text-green-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd"
              ></path>
            </svg>

            Payment secured by Nvoko
          </span>
        </span>
      </div>

      <div class="deal-status">
        <div v-if="!song.purchased" class="item">
          <div class="flex items-center w-full gap-3">
            <div class="flex-shrink-0">
              <svg
                class="w-5 h-5 mt-0.5 text-nvoko animate-pulse"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <p
              class="flex items-center justify-between flex-1 text-sm font-bold text-nvoko "
            >
              Contract hasn't been paid yet.

              <button class="primary" @click="openPaymentDropin">Pay</button>
            </p>
          </div>
        </div>
        <!-- <div v-if="song.payment_done_off_platform == null" class="item">
          <div class="flex items-center w-full gap-3">
            <div class="flex-shrink-0">
              <svg
                class="w-5 h-5 mt-0.5 text-nvoko animate-pulse"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <p
              class="flex items-center justify-between flex-1 text-sm font-bold text-nvoko "
            >
              Payment status undetermined.

              <button class="primary" @click="openPaymentDoneOffPlatformModal">
                Set status
              </button>
            </p>
          </div>
        </div> -->
      </div>
      <div class="collaborators">
        <div
          class="card company"
          v-if="companyOptions.applicable && song.collaborators.length > 1"
        >
          <template v-if="loading">
            <div class="flex items-center justify-center w-full h-full">
              <div class="spinner" />
            </div>
          </template>

          <template v-else>
            <div class="step">
              <span class="question">
                Who would be entering into this agreement?
              </span>
              <div class="inputs">
                <div class="radiobuttons">
                  <div
                    class="radio-group"
                    v-for="(choice, i) in companyQuestion.choices"
                    :key="i"
                  >
                    <label class="radio">
                      <input
                        type="radio"
                        :value="choice"
                        v-model="companyQuestion.answer"
                      />
                      <span class="text">
                        {{ choice.label }}
                        <template v-if="choice.value">
                          <br />
                          + <br />
                          {{ artist.name }}
                        </template>
                      </span>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="primary outline"
              :disabled="submitting === song.id"
              @click="updateCompanyData"
            >
              {{ submitting ? "Submitting" : "Continue" }}
            </button>
          </template>
        </div>
        <template
          v-if="!companyOptions.applicable || song.collaborators.length === 1"
        >
          <div
            class="card"
            v-for="({
              info,
              services,
              percentage = {},
              isMe,
              songHost,
              hostActive,
              status,
              deal_id,
              fee_status,
              fee,
              inducement,
              category,
              recoupableFee
              // chargesEnabled,
              // fee
            },
            i) in song.collaborators"
            :key="i"
          >
            <div class="top">
              <div class="artist">
                <img
                  class="w-full h-full object-cover"
                  v-if="info.avatar_url"
                  :src="info.avatar_url"
                />
                <div v-else class="initials">
                  {{ info.name | initials }}
                </div>
                <div class="details">
                  <span class="name">{{ info.name }}</span>
                  <span class="contribution" v-if="!songHost">
                    {{ services | contribution }}
                  </span>
                </div>
              </div>
              <div class="party-icon" v-if="!i">HOST</div>
              <div class="check" v-else-if="category === 'completed'">
                <img src="../assets/icons/Check.svg" width="16" height="16" />
              </div>
              <div class="attention" v-else-if="category === 'action-needed'">
                <img
                  src="../assets/icons/Attention.svg"
                  width="16"
                  height="16"
                />
              </div>
            </div>
            <template v-if="isMe || song.isHost">
              <div class="split-name">Royalties and Payments</div>
              <div class="split">
                <div>
                  <span class="type">Recording</span>
                  <span
                    class="percentage"
                    :class="{
                      invalid:
                        percentage.recording > 100 || percentage.recording < 0
                    }"
                  >
                    {{ percentage.recording + "%" }}
                  </span>
                </div>
                <div>
                  <span class="type">Publishing</span>
                  <span
                    class="percentage"
                    :class="{
                      invalid:
                        percentage.publishing > 100 || percentage.publishing < 0
                    }"
                  >
                    {{ percentage.publishing + "%" }}
                  </span>
                </div>
                <div v-if="!songHost">
                  <span class="type">Guest Fee</span>
                  <span class="percentage">
                    {{ fee | currency }}
                  </span>
                </div>
                <div v-if="!songHost">
                  <span class="type">Recoupable</span>
                  <span class="percentage">
                    {{ recoupableFee | currency }}
                  </span>
                </div>
              </div>
            </template>
            <div
              class="deal-status"
              v-if="(isMe || song.isHost) && !songHost && status !== 'pending'"
            >
              <div class="item">
                <span class="type">Deal Terms</span>
                <span
                  class="status flex items-center justify-start gap-2"
                  v-if="status === 'contract-ready'"
                >
                  Completed

                  <Icon name="check" color="#e3507a" />
                </span>
                <span class="status" v-else-if="!song.purchased">
                  Purchase Pending
                </span>
                <span class="status" v-else>
                  Pending | Waiting on
                  <b>
                    {{
                      ["contract-signed-host", "deal-updated-host"].includes(
                        status
                      )
                        ? song.isHost
                          ? info.name
                          : "You"
                        : song.isHost
                        ? "You"
                        : song.collaborators[0].info.name
                    }}
                  </b>
                  to sign
                </span>
                <button
                  class="primary"
                  @click="
                    openModal('review', deal_id, status, info, hostActive)
                  "
                  v-if="
                    (isMe || song.isHost) &&
                      (status.includes('deal-updated-') ||
                        status.includes('contract-signed-'))
                  "
                >
                  Review
                </button>
                <button
                  class="primary"
                  @click="
                    openModal('view-terms', deal_id, status, info, hostActive)
                  "
                  v-else
                >
                  View
                </button>
              </div>
              <div class="item" v-if="song.purchased">
                <span class="type">Main Agreement</span>
                <span
                  class="status flex items-center justify-start gap-2"
                  v-if="status === 'contract-ready'"
                >
                  Completed
                  <Icon name="check" color="#e3507a" />
                </span>
                <span class="status" v-else>
                  <!-- Waiting on
                  <b>
                    {{
      ["contract-signed-host", "deal-updated-host"].includes(
        status
      )
        ? song.isHost
          ? info.name
          : "You"
        : song.isHost
          ? "You"
          : song.collaborators[0].info.name
    }}
                  </b>
                  to sign -->
                  {{
                    ["contract-signed-host", "deal-updated-host"].includes(
                      status
                    )
                      ? song.isHost
                        ? "Preview contract"
                        : "Sign now"
                      : song.isHost
                      ? "Sign now"
                      : "Preview contract"
                  }}
                </span>
                <button
                  class="primary"
                  :class="{
                    outline:
                      (isMe || song.isHost) && status === 'contract-ready'
                  }"
                  @click="openModal('view', deal_id, status, info, hostActive)"
                  :disabled="!isMe && !song.isHost"
                >
                  View
                </button>
              </div>
              <div
                class="item"
                v-if="
                  status === 'contract-ready' &&
                    inducement &&
                    inducement.applicable
                "
              >
                <span class="type">
                  {{
                    song.isHost ? song.collaborators[0].info.name : info.name
                  }}'s Inducement Agreement
                </span>
                <span class="status" v-html="inducement.status"></span>
                <button
                  class="primary"
                  @click="
                    openModal(
                      'inducement',
                      deal_id,
                      status,
                      info,
                      hostActive,
                      inducement
                    )
                  "
                  v-if="status === 'contract-ready' && inducement.canSign"
                >
                  Sign Inducement Contract
                </button>
                <button
                  class="primary outline"
                  @click="
                    openModal(
                      'inducement',
                      deal_id,
                      status,
                      info,
                      hostActive,
                      inducement
                    )
                  "
                  v-if="status === 'contract-ready' && inducement.signature"
                >
                  View Inducement Contract
                </button>
              </div>
            </div>
            <div class="buttons" v-if="!songHost">
              <button
                class="primary"
                @click="openModal('create', deal_id, status, info, hostActive)"
                v-if="status === 'pending' && (isMe || song.isHost)"
              >
                Create Deal
              </button>
              <!--
              <button
                class="primary outline"
                @click="openModal('view', deal_id, status, info, song.isHost)"
                v-if="status === 'contract-ready'"
              >
                Print Contract
              </button>
              <button
                class="primary"
                @click="
                  openModal('fund', deal_id, status, info, song.isHost, fee)
                "
                :disabled="!chargesEnabled"
                :title="
                  !chargesEnabled ? 'This user has not enabled charges yet' : ''
                "
                v-if="
                  song.isHost &&
                    status === 'contract-ready' &&
                    fee_status === 'pending'
                "
              >
                Fund Deal
              </button>
              -->
              <button
                class="primary"
                @click="releaseFunds(deal_id, song.id)"
                v-if="
                  hostActive &&
                    status === 'contract-ready' &&
                    ['requires_capture', 'processing'].includes(fee_status)
                "
                :disabled="
                  fee_status === 'processing' || submitting === deal_id
                "
              >
                {{ submitting === deal_id ? "Submitting..." : "Release Funds" }}
              </button>
            </div>
            <ContractSteps :history="song.history" :artist_id="info.id" />
          </div>
        </template>
        <div class="contacts">
          <div class="cards" v-if="song.contacts && song.contacts.length > 0">
            <h2
              class="mb-4 text-xl font-bold text-left uppercase "
              style="width: calc(100%);"
            >
              Pending Collaborators
            </h2>

            <div
              class="flex flex-col items-start justify-center w-full gap-5"
              style="width: calc(100%);"
            >
              <div
                v-for="pendingArtist in song.contacts"
                :key="pendingArtist.invited_artist_email + '_pending'"
                class="flex w-full opacity-75 cursor-not-allowed artist-card"
                style="margin-bottom: 0; width: 100%; cursor: not-allowed;"
              >
                <div class="avatar">
                  <div class="picture" v-if="pendingArtist.avatar_url">
                    <img :src="pendingArtist.avatar_url" />
                  </div>
                  <div class="initials" v-else>
                    {{ pendingArtist.invited_artist_email | initials }}
                  </div>
                </div>
                <div class="flex-1 truncate name">
                  {{ pendingArtist.invited_artist_email }}
                </div>
              </div>
            </div>
            <span
              style="width: calc(100% - 60px);"
              class="w-full h-0.5 bg-gray-300 my-5"
            />
          </div>
        </div>
      </div>
      <div class="add-collaborator" v-if="song.isHost">
        <button class="primary" @click="addingCollaborator = true">
          <img src="../assets/icons/Track-Note.svg" />
          Add Collaborator
        </button>
      </div>
    </div>
    <AddCollaborator
      v-if="addingCollaborator"
      @close="success => collaboratorAdded(success)"
      :song="song"
      :host_id="artist.id"
    />
    <CreateDeal
      v-if="modalName === 'create-deal'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ReviewDeal
      v-if="modalName === 'review-deal'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ReviewDealDropin
      v-if="modalName === 'review-deal-dropin'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ViewDealTerms
      v-if="modalName === 'view-terms'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ViewContract
      v-if="modalName === 'view-contract'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ProjectFunding
      v-if="modalName === 'project-funding'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <InducementContract
      v-if="modalName === 'inducement-contract'"
      @close="clearDeal"
      :deal="currentDeal"
      :inducement="inducement"
    />
    <PaymentDropinModal
      v-if="modalName === 'payment-dropin'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <PaymentDoneOffPlatform
      v-if="modalName === 'payment-done-off-platform'"
      @close="onCloseDoneOffPlatform"
      :deal="currentDeal"
    />
  </div>
</template>

<script>
import AddCollaborator from "@/components/modals/AddCollaborator";
import CreateDeal from "@/components/modals/CreateDeal";
import ReviewDeal from "@/components/modals/ReviewDeal";
import ReviewDealDropin from "@/components/modals/ReviewDealDropin";
import ViewDealTerms from "@/components/modals/ViewDealTerms";
import ViewContract from "@/components/modals/ViewContract";
import ProjectFunding from "@/components/modals/ProjectFunding";
import InducementContract from "@/components/modals/InducementContract";
import ContractSteps from "@/components/ContractSteps";
// import PaymentDropin from "@/components/PaymentDropin";
import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import RELEASE_FUNDS from "@/api/mutations/RELEASE_FUNDS.gql";
import UPDATE_SONG from "@/api/mutations/UPDATE_SONG.gql";
import UPDATE_DEAL from "@/api/mutations/UPDATE_DEAL.gql";
import PaymentDropinModal from "@/components/modals/PaymentDropinModal";
import PaymentDoneOffPlatform from "@/components/modals/PaymentDoneOffPlatform.vue";
import Icon from "@/components/Icon.vue";

export default {
  name: "SongPreview",
  components: {
    AddCollaborator,
    CreateDeal,
    ReviewDeal,
    ViewDealTerms,
    ViewContract,
    ProjectFunding,
    InducementContract,
    ContractSteps,
    // PaymentDropin,
    PaymentDropinModal,
    ReviewDealDropin,
    PaymentDoneOffPlatform,
    Icon
  },
  props: {
    songs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addingCollaborator: false,
      currentDeal: null,
      inducement: null,
      modalName: "",
      submitting: "",
      companyQuestion: {
        answer: {},
        choices: []
      },
      loading: false,
      isEditingName: false
    };
  },
  mounted() {
    this.updateCompanyQuestion();
    if (
      this.companyOptions.applicable &&
      this.song?.collaborators?.length > 1
    ) {
      this.updateCompanyData();
    }
    console.log(this.song);
  },
  watch: {
    "song.id"() {
      this.updateCompanyQuestion();
    }
  },
  computed: {
    song() {
      const { song } = this.$route.query;
      if (!song) return {};
      return this.songs.find(el => el.id === song);
    },
    artist() {
      return this.$store.getters["account/getArtist"];
    },
    companyOptions() {
      const applicable = typeof this.song.isPartyCompany !== "boolean";
      const valid = typeof this.companyQuestion.answer.value === "string";
      return { applicable, valid };
    },
    songStatus() {
      return this.song.collaborators?.[1]?.status;
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    },
    contribution(data) {
      return data
        .map(el => el.charAt(0).toUpperCase() + el.slice(1))
        .join(", ");
    },
    currency(value) {
      if (!value) return "$0";
      return Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0
      });
    }
  },
  methods: {
    editSongName() {
      this.isEditingName = true;
      this.tempSongName = this.song.name;
    },
    async saveSongName() {
      this.isEditingName = false;
      if (this.tempSongName !== this.song.name) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_SONG,
            variables: {
              id: this.song.id,
              update: { name: this.tempSongName }
            },
            update: (store, { data: { update_songs_by_pk } }) => {
              const data = store.readQuery({
                query: GET_SONGS,
                variables: {
                  artistId: this.$store.getters["account/getArtistId"]
                }
              });

              const songIndex = data.songs.findIndex(
                el => el.id === this.song.id
              );
              data.songs[songIndex] = {
                ...data.songs[songIndex],
                ...update_songs_by_pk
              };

              store.writeQuery({
                query: GET_SONGS,
                variables: {
                  artistId: this.$store.getters["account/getArtistId"]
                },
                data
              });
            }
          });

          this.$emit("refetchSongs");
        } catch (e) {
          console.error(e);
        }
      }
    },

    onCloseDoneOffPlatform(reload = false) {
      this.clearDeal();
      if (reload) this.$emit("refetchSongs");
    },
    setCurrentDeal(type, deal_id, status, artist, hostActive, fee) {
      const { collaborators } = this.song;
      console.log(this.song);
      const songHost = collaborators[0].info;
      const songHostActive =
        songHost.id === this.$store.getters["account/getArtistId"];
      this.currentDeal = {
        myArtist: songHostActive ? songHost : artist,
        artist: songHostActive ? artist : songHost,
        party: hostActive ? "host" : "guest",
        song_id: this.song.id,
        song_name: this.song.name,
        purchased: this.song.purchased,
        id: deal_id,
        paid_by: this.song?.paid_by,
        status,
        fee,
        split: collaborators.flatMap(({ deal_id, percentage }) =>
          deal_id ? [{ deal_id, percentage }] : []
        )
      };
    },
    openModal(type, deal_id, status, artist, hostActive, inducement, fee) {
      const { collaborators } = this.song;
      const songHost = collaborators[0].info;
      const songHostActive =
        songHost.id === this.$store.getters["account/getArtistId"];
      this.currentDeal = {
        myArtist: songHostActive ? songHost : artist,
        artist: songHostActive ? artist : songHost,
        party: hostActive ? "host" : "guest",
        song_id: this.song.id,
        song_name: this.song.name,
        purchased: this.song.purchased,
        paid_by: this.song?.paid_by,
        id: deal_id,
        status,
        fee,
        split: collaborators.flatMap(({ deal_id, percentage }) =>
          deal_id ? [{ deal_id, percentage }] : []
        )
      };

      switch (type) {
        case "pay":
          this.modalName = "payment-dropin";
          break;
        case "create":
          this.modalName = "create-deal";
          break;
        case "review":
          this.modalName = "review-deal-dropin";
          break;
        case "view-terms":
          this.modalName = "view-terms";
          break;
        case "view":
          this.modalName = "view-contract";
          break;
        case "payment-done-off-platform":
          this.modalName = "payment-done-off-platform";
          break;
        case "fund":
          this.modalName = "project-funding";
          break;
        case "inducement":
          this.inducement = inducement;
          this.modalName = "inducement-contract";
          break;
        default:
          break;
      }
    },
    openPaymentDropin() {
      const collaborator = this.song.collaborators[1];
      this.openModal(
        "pay",
        collaborator.deal_id,
        status,
        collaborator.info,
        true
      );
    },
    openPaymentDoneOffPlatformModal() {
      const collaborator = this.song.collaborators[1];
      this.openModal(
        "payment-done-off-platform",
        collaborator.deal_id,
        status,
        collaborator.info,
        true
      );
    },
    clearDeal() {
      this.currentDeal = null;
      this.inducement = null;
      this.modalName = "";
    },
    updateCompanyQuestion() {
      const choices = this.artist.company_artists.flatMap(
        ({ company, recording_agreement, recording_agreement_third_party }) => {
          if (!company) return [];
          return [
            {
              value: company.id,
              label: company.name,
              recording_agreement,
              recording_agreement_third_party
            }
          ];
        }
      );

      choices.unshift({
        value: "",
        label: this.artist.name,
        recording_agreement: this.artist.company_artists.some(
          ({ recording_agreement }) => !!recording_agreement
        )
      });

      this.$set(this.companyQuestion, "choices", choices);
      this.$set(this.companyQuestion, "answer", {});
    },
    updateCompanyData() {
      const {
        song: { isHost },
        updateHostCompanyData,
        updateGuestCompanyData
      } = this;

      this.loading = true;

      return isHost ? updateHostCompanyData() : updateGuestCompanyData();
    },
    updateHostCompanyData() {
      const {
        song: { id },
        companyQuestion: {
          answer: {
            value,
            recording_agreement,
            recording_agreement_third_party
          }
        }
      } = this;

      this.submitting = id;
      let update = {};

      if (!value)
        update = { is_host_company: false, rds: recording_agreement ? 4 : 5 };
      else {
        let rds;
        if (recording_agreement) rds = recording_agreement_third_party ? 2 : 1;
        else rds = recording_agreement_third_party ? 2 : 3;

        update = { is_host_company: true, host_company_id: value, rds };
      }

      this.$apollo.mutate({
        mutation: UPDATE_SONG,
        variables: { id, update },
        update: (store, { data: { update_songs_by_pk } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === id);
          data.songs[song] = { ...data.songs[song], ...update_songs_by_pk };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.loading = false;
          this.$emit("close");
        }
      });
    },
    updateGuestCompanyData() {
      const {
        song: { id, myDeal },
        companyQuestion: {
          answer: { value }
        }
      } = this;

      this.submitting = id;
      let update = {};

      if (!value) update = { is_guest_company: false };
      else update = { is_guest_company: true, guest_company_id: value };

      this.$apollo.mutate({
        mutation: UPDATE_DEAL,
        variables: { id: myDeal, update },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === id);
          const index = data.songs[song].splits[0].split_details.findIndex(
            el => el.deal_id === myDeal
          );

          data.songs[song].splits[0].split_details[index].deal = {
            ...data.songs[song].splits[0].split_details[index].deal,
            ...update_deals_by_pk
          };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.loading = false;
          this.$emit("close");
        }
      });
    },
    collaboratorAdded(success) {
      this.addingCollaborator = false;
      if (success) this.$emit("refetchSongs");
    },
    releaseFunds(deal, song_id) {
      this.submitting = deal;

      this.$apollo.mutate({
        mutation: RELEASE_FUNDS,
        variables: { deal },
        update: (store, { data: { stripe_capture_funds } }) => {
          if (!stripe_capture_funds.success) return;
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === song_id);
          const index = data.songs[song].splits[0].split_details.findIndex(
            el => el.deal_id === deal
          );

          data.songs[song].splits[0].split_details[index].deal = {
            ...data.songs[song].splits[0].split_details[index].deal,
            fee_status: "succeeded"
          };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.$emit("close");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.right-panel {
  display: flex;
  height: 100%;
  flex-direction: column;
  background: $bg;

  .panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.25rem 2.5rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .panel-header {
      font-size: 1.7rem;
    }

    .collaborators {
      margin-top: 1.75rem;

      .card {
        padding: 16px 0;
        margin-bottom: 31px;
        border: 1px solid $gray;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0px 16px 24px rgb(59 59 59 / 8%);

        &.company {
          padding: 0 10px;
          display: flex;
          flex-direction: column;

          .step {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-top: 42px;

            .question {
              width: 100%;
              height: fit-content;
              font-weight: 500;
              font-size: 20px;
              line-height: 22px;
              text-align: center;
              letter-spacing: -1.025px;
              color: $text;
              margin-bottom: 24px;
            }

            .inputs {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;

              .radiobuttons {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .radio-group {
                  .radio {
                    width: 144px;
                    text-align: center;
                    padding: 10px;
                  }
                }
              }
            }
          }

          button {
            margin-top: -10px;
            margin-bottom: 18px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .top {
          display: flex;
          width: 100%;
          padding: 0 20px;

          .artist {
            display: flex;
            flex: 1;
            align-items: center;

            img {
              width: 55px;
              height: 55px;
              border-radius: 50%;
            }

            .initials {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 55px;
              width: 55px;
              font-family: sans-serif;
              font-size: 22px;
              border-radius: 50%;
              background-color: $black;
              border: 1px solid $accent;
              color: $white;
            }

            .details {
              margin-left: 1rem;
              font-size: 1.25rem;
              display: flex;
              flex-direction: column;

              .contribution {
                @include details-light();
              }
            }
          }

          .party-icon {
            background-color: black;
            color: white;
            font-size: 15px;
            height: 22px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1px 4px 2px;
          }

          .check {
            img {
              -webkit-filter: invert(1);
              filter: invert(1);
            }
          }
        }

        .split-name {
          margin: 10px 20px 0;
          font-size: 1rem;
        }

        .split {
          padding-top: 0.75rem;
          display: flex;

          div {
            margin: 0 20px;
            display: flex;
            flex-direction: column;

            .type {
              @include details-light();
              color: rgb(155, 155, 155);
              // font-weight: 700;
            }

            .percentage {
              margin-top: 0.35rem;
              font-family: "Roboto Mono";
              font-size: 1.3rem;
            }

            .invalid {
              color: $accent;
            }
          }
        }

        .buttons {
          padding: 0.5rem 20px 0;
          display: flex;
          justify-content: flex-end;

          button {
            display: flex;
            margin-left: 5px;

            img {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    .add-collaborator {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        display: flex;
        padding: 0.75rem 2.5rem;

        img {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.deal-status {
  padding: 0.75rem 20px;

  .item {
    border: 1px #00000000 solid;
    border-radius: 10px;
    padding: 10px 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    background-color: rgb(252, 238, 242);

    .type {
      // @include details-light();
      color: rgb(227, 80, 122);
      font-weight: 700;
    }

    .status {
      font-size: 1.25rem;
      margin-left: 0.25rem;
      @include details-light();
      color: rgb(232, 110, 145);
    }

    button {
      margin-left: auto;
    }
  }
}

.artists-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 204px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $accent rgba(#cdcccc, 0.3);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    @include background-opacity(#cdcccc, 0.3);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent;
    border-radius: 5px;
  }

  &:after {
    content: "";
    flex: 1;
  }
}

.artist-card {
  display: flex;
  align-items: center;
  width: calc(100%);
  padding: 8px;
  background-color: #f6f6f6;
  border: 2px solid #f6f6f6;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;

  &.active,
  &:hover {
    border: 2px solid #838383;
    box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
  }

  &.active {
    background: #ffffff;
    border: 2px solid $accent;
  }

  .avatar {
    .picture {
      img {
        height: 57px;
        width: 57px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .initials {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 57px;
      width: 57px;
      font-family: sans-serif;
      font-size: 20px;
      background-color: $black;
      border: 1px solid $accent;
      color: $white;
      border-radius: 50%;
    }
  }

  .name {
    margin-left: 16px;
    flex: 1;
    color: $text;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.2475px;
    margin-right: 4px;
  }
}

.cards {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 16px;
  background: #fff;
  box-shadow: 0px 16px 24px rgb(59 59 59 / 8%);
}
</style>
