<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <div class="title">
                <h3>Upload a signed contract</h3>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="details">
                <div class="file-upload-container">
                  <div class="file-input-wrapper">
                    <input
                      type="file"
                      @change="handleFileUpload"
                      multiple
                      ref="fileInput"
                      class="file-input"
                    />
                  </div>
                  <div v-if="files.length > 0" class="file-list flex-1">
                    <div
                      v-for="(file, index) in files"
                      :key="index"
                      class="file-item"
                    >
                      <span v-html="getFileIcon(file)" class="file-icon"></span>
                      <span class="file-name flex-1 overflow-ellipsis">{{
                        file.name
                      }}</span>
                      <span class="file-size">{{
                        formatFileSize(file.size)
                      }}</span>
                      <button @click="removeFile(index)" class="remove-file">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-x"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M18 6l-12 12" />
                          <path d="M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    @click="triggerFileInput"
                    v-else
                    class="no-files flex flex-col items-center justify-center gap-5"
                  >
                    <svg
                      width="65"
                      height="65"
                      viewBox="0 0 65 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4938_7923)">
                        <path
                          d="M43.1673 43.1667L32.5007 32.5L21.834 43.1667"
                          stroke="#FF035A"
                          stroke-width="5.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M32.5 32.5V56.5"
                          stroke="#FF035A"
                          stroke-width="5.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M54.8713 49.5491C57.4722 48.1311 59.5268 45.8874 60.7109 43.1721C61.895 40.4567 62.1412 37.4244 61.4105 34.5536C60.6798 31.6828 59.0139 29.1371 56.6757 27.3183C54.3376 25.4994 51.4602 24.511 48.4979 24.5091H45.1379C44.3308 21.387 42.8264 18.4886 40.7378 16.0317C38.6492 13.5748 36.0309 11.6233 33.0795 10.324C30.1282 9.02471 26.9207 8.41136 23.6983 8.53009C20.4758 8.64882 17.3221 9.49654 14.4744 11.0095C11.6267 12.5225 9.15904 14.6613 7.25693 17.2653C5.35483 19.8692 4.06778 22.8705 3.49255 26.0435C2.91733 29.2164 3.06889 32.4785 3.93584 35.5844C4.8028 38.6904 6.36259 41.5594 8.49794 43.9757"
                          stroke="#FF035A"
                          stroke-width="5.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M43.1673 43.1667L32.5007 32.5L21.834 43.1667"
                          stroke="#FF035A"
                          stroke-width="5.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4938_7923">
                          <rect
                            width="64"
                            height="64"
                            fill="white"
                            transform="translate(0.5 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    No files uploaded yet. Click "Upload Files" to begin.
                  </div>
                  <div v-if="errorMessage" class="error-message">
                    {{ errorMessage }}
                  </div>
                </div>
              </div>
              <div class="buttons">
                <button
                  @click="$emit('close')"
                  class="primary outline flex-1"
                  style="width: auto;"
                >
                  Cancel
                </button>
                <button
                  @click="triggerFileInput"
                  class="primary flex-1"
                  style="width: auto ;"
                >
                  Browse file
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { jsPDF } from "jspdf";

import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";

import GET_CONTRACT from "@/api/queries/GET_CONTRACT.gql";

export default {
  name: "ViewContract",
  components: {
    ModalCloseIcon
  },
  props: {
    deal: Object
  },

  data() {
    return {
      files: [
        { name: "contract.pdf", size: 1024000, type: "application/pdf" },
        {
          name: "terms.docx",
          size: 512000,
          type:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        },
        { name: "signature.jpg", size: 256000, type: "image/jpeg" }
      ],
      maxFileSize: 5 * 1024 * 1024, // 5MB
      allowedFormats: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png"
      ],
      errorMessage: ""
    };
  },
  computed: {
    host_signature() {
      const signature = this.contract.signatures.find(
        ({ party, type }) => party === "host" && type === "contract"
      );
      return signature ? signature.value : {};
    },
    guest_signature() {
      const signature = this.contract.signatures.find(
        ({ party, type }) => party === "guest" && type === "contract"
      );
      return signature ? signature.value : {};
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const newFiles = Array.from(event.target.files);
      this.errorMessage = "";

      newFiles.forEach(file => {
        if (!this.allowedFormats.includes(file.type)) {
          this.errorMessage = `File "${file.name}" is not in an allowed format. Please upload PDF, DOC, DOCX, JPG, or PNG files only.`;
        } else if (file.size > this.maxFileSize) {
          this.errorMessage = `File "${file.name}" exceeds the maximum file size of 5MB.`;
        } else {
          this.files.push(file);
        }
      });

      // Clear the file input
      event.target.value = "";
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    getFileIcon(file) {
      const extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      switch (extension) {
        case "pdf":
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M7 3H17C18.1046 3 19 3.89543 19 5V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3ZM7 5V19H17V5H7ZM10 10V8H14V10H10ZM10 12V14H14V12H10ZM10 16V18H14V16H10Z"/></svg>`;
        case "doc":
        case "docx":
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM9 13V15H15V13H9ZM9 17V19H15V17H9Z"/></svg>`;
        case "xls":
        case "xlsx":
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM10 19H8V17H10V19ZM16 19H14V17H16V19ZM10 15H8V13H10V15ZM16 15H14V13H16V15Z"/></svg>`;
        case "jpg":
        case "jpeg":
        case "png":
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3ZM5 19V5H19V19H5ZM15 15V13.5C15 12.6716 14.3284 12 13.5 12C14.3284 12 15 11.3284 15 10.5V9H9V15H15ZM13 13H11V11H13V13Z"/></svg>`;
        default:
          return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"/></svg>`;
      }
    },
    formatFileSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  width: 100%;
  height: 50vh;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1.5rem 32px;

  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .title {
    display: flex;
    width: 100%;
    justify-content: center;

    .avatar {
      width: 123px;
      height: 123px;
      overflow: hidden;
      border-radius: 15px;

      img {
        object-fit: cover;
      }
    }

    h2 {
      margin-left: 32px;
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: -0.666667px;
      color: $text;

      span {
        font-weight: 700;
      }
    }
  }
}

.modal-body {
  width: 100%;
  height: calc(100% - 158px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
  padding-top: 0;

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .text {
      ::v-deep p {
        padding: 10px 0;
      }

      ::v-deep h1 {
        font-size: 2em;
      }

      ::v-deep h2 {
        font-size: 1.5em;
      }
    }

    .signatures {
      margin: 1rem;
      display: flex;
      justify-content: space-around;

      div {
        display: flex;
        flex-direction: column;

        .signature {
          font-size: 2rem;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    margin-bottom: -2.5rem;

    button {
      width: 136px;
      height: 44px;
      margin: 0 16px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}

.file-upload-container {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.file-input-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.file-input {
  display: none;
}

.upload-button {
  background-color: $accent;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($accent, 10%);
  }

  i {
    margin-right: 8px;
  }
}

.file-list {
  // max-height: 300px;

  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid #cdcccc;
  width: 100%;
  gap: 10px;

  i {
    font-size: 24px;
    margin-right: 10px;
    color: $accent;
    width: 24px;
  }

  .file-name {
    font-weight: 500;
    // max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .file-size {
    color: #6c757d;
    margin-right: 10px;
  }

  .remove-file {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    margin-left: 10px;

    &:hover {
      color: darken(#dc3545, 10%);
    }
  }
}

.no-files {
  text-align: center;
  font-style: italic;
  cursor: pointer;
}
</style>
