<template>
  <div class="container">
    <Loader v-if="$apollo.queries.results.loading" color="#FF035A" size="50" />
    <div v-else class="content">
      <div class="left-panel">
        <div class="filters">
          <div class="search">
            <div class="icon">
              <img src="../assets/icons/Search.svg" />
            </div>
            <input
              type="search"
              v-model="searchQuery"
              placeholder="Search by song name"
            />
          </div>
          <CustomSelect
            default="Sort: A to Z"
            @setValue="value => (sortBy = value)"
            :options="[
              {
                value: 'az',
                label: 'Sort: A to Z'
              },
              {
                value: 'za',
                label: 'Sort: Z to A'
              }
            ]"
          />
        </div>
        <div class="songs-container">
          <div class="cards-wrapper">
            <div class="cards">
              <div
                v-for="({ title, songs, text_cleared }, i) in songsByCategory"
                :key="i"
                class="category"
              >
                <div class="category-title">{{ title }}</div>
                <div v-if="!songs.length" class="category-cleared">
                  {{ text_cleared }}
                </div>
                <div
                  v-for="{ id, name, collaborators, isHost, myRPM } in songs"
                  class="card"
                  :key="id"
                  :class="{
                    'selected-card': id == active,
                    'action-needed': title == 'ACTION NEEDED'
                  }"
                  @click="selectSong(id)"
                >
                  <div class="top">
                    <span class="title">{{ name }}</span>
                    <div class="party-icon">{{ isHost ? "H" : "G" }}</div>
                  </div>
                  <div class="collaborators">
                    <div
                      v-for="({ info }, i) in collaborators"
                      :key="i"
                      class="collaborator"
                      :title="info.name"
                    >
                      <img
                        class="object-cover w-full h-full"
                        v-if="info.avatar_url"
                        :src="info.avatar_url"
                      />
                      <!--
                      <div v-else class="initials">
                        {{ info.name | initials }}
                      </div>
                      -->
                    </div>
                  </div>
                  <div class="divider" />
                  <div class="my-rpm">
                    <!-- <button>i</button> -->
                    <span>
                      My Split:
                      <strong>{{ myRPM || "100% Rec, 100% Pub" }}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="add-song">
          <button class="primary" @click="showModal = true">
            <img src="../assets/icons/CreateDeal.svg" style="height: 16px" />
            Create Deal
          </button>
        </div>
        -->
      </div>
      <SongPreview
        :songs="songs"
        @refetchSongs="$apollo.queries.results.refetch()"
      />
      <AddSong
        v-if="showModal"
        @close="showModal = false"
        :my-artist="myArtist"
        add-new-required
      />
    </div>
  </div>
</template>

<script>
import SongPreview from "@/components/SongPreview.vue";
import Loader from "@/components/Loader.vue";
import AddSong from "@/components/modals/AddSong.vue";
import CustomSelect from "@/components/Select.vue";

import GET_SONGS from "@/api/queries/GET_SONGS.gql";

export default {
  components: {
    SongPreview,
    Loader,
    AddSong,
    CustomSelect
  },
  data() {
    return {
      showModal: false,
      songs: [],
      myArtist: null,
      searchQuery: "",
      sortBy: "az"
    };
  },
  computed: {
    active() {
      return this.$route.query.song;
    },
    filteredSongs() {
      console.log(this.searchQuery.toLowerCase());
      return this.songs.filter(el =>
        el.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    user() {
      return this.$store.getters["account/getUser"];
    },
    songsByCategory() {
      const multiple = this.sortBy === "az" ? 1 : -1;

      const sorted = [
        {
          title: "ACTION NEEDED",
          text_cleared: "There are no songs waiting on you.",
          songs: []
        },
        {
          title: "PENDING",
          text_cleared: "There are no songs waiting on someone else.",
          songs: []
        },
        {
          title: "COMPLETED",
          text_cleared: "There are no completed songs yet.",
          songs: []
        }
      ];

      const songsClone = [...this.songs];

      songsClone
        .sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1 * multiple;
          if (nameA > nameB) return 1 * multiple;
          return 0;
        })
        .filter(el =>
          el.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        .forEach(song => {
          if (
            song.collaborators.some(
              ({ category }) => category === "action-needed"
            )
          ) {
            sorted[0].songs.push(song);
            return;
          } else if (
            song.collaborators.some(({ category }) => category === "pending")
          ) {
            sorted[1].songs.push(song);
            return;
          } else if (
            song.collaborators.some(({ category }) => category === "completed")
          ) {
            sorted[2].songs.push(song);
            return;
          } else sorted[1].songs.unshift(song);
        });

      return sorted;
    }
  },
  watch: {
    results({ songs }) {
      this.songs = songs;
      this.myArtist = this.$store.getters["account/getArtist"];
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    selectSong(id) {
      this.$router.push({ name: "Catalog", query: { song: id } });
    },
    isMe(artist) {
      return artist.id === this.$store.getters["account/getArtistId"];
    },
    getCategory(isHost, isGuest, status) {
      if (!isHost && !isGuest) return "null";

      let myParty = isHost ? "host" : "guest";
      let otherParty = isHost ? "guest" : "host";

      if (status.includes(`-${myParty}`)) return "pending";
      if (status.includes(`-${otherParty}`) || status === "pending")
        return "action-needed";

      if (status === "contract-ready") return "completed";
      return "pending";
    },
    checkChargesEnabled(artist) {
      return !!((artist.artist_users || [])[0]?.user || {}).charges_enabled;
    },
    getViewerInfo(collaborators) {
      const { recording, publishing } = collaborators.find(
        el => el.isMe
      ).percentage;
      return {
        isHost: collaborators[0].isMe,
        myRPM: recording + "% Rec, " + publishing + "% Pub"
      };
    },
    getHostPercentage(guests = []) {
      let resp = {
        recording: 100,
        publishing: 100
      };
      guests.forEach(guest => {
        Object.keys(guest.percentage).forEach(el => {
          if (resp[el]) resp[el] -= guest.percentage[el];
        });
      });
      return resp;
    },
    formatArtist(artist) {
      if (artist.name) return artist;
      let name = artist.artist_users[0]?.user.email;
      return { ...artist, name };
    },
    getInducementLetter(
      contract,
      viewer,
      artistUser,
      artistNames,
      isPartyCompany
    ) {
      if (!contract) return { applicable: false };

      const inducementSignature = contract.signatures.find(
        ({ party, type }) => party === viewer && type === "inducement"
      );

      const applicable = !!isPartyCompany;
      const isArtist = artistUser[viewer] === this.user?.id;

      let status;
      if (inducementSignature) {
        status = "Completed &#9989;";
      } else if (isArtist) {
        status = "Waiting on <b>You</b>";
      } else {
        status = `Waiting on <b>${artistNames[viewer]}</b>`;
      }

      return {
        contract_id: contract.id,
        contract_text: contract[`generated_${viewer}_inducement`],
        contract_url: contract[`${viewer}_inducement_url`],
        signature: inducementSignature,
        applicable,
        canSign:
          applicable &&
          artistUser[viewer] === this.user?.id &&
          !inducementSignature,
        status
      };
    },
    getRecoupableFee(contract) {
      const recoupableFeeQ = contract.contract_answers?.find(
        ({ question_id }) =>
          question_id === "434b07dc-0218-49c1-9ce1-e1101c93a239"
      );
      return recoupableFeeQ?.answer.value;
    },
    formatSongs(songs) {
      return songs.map(
        ({
          id,
          name,
          splits,
          host: hostArtist,
          purchases,
          is_host_company,
          contacts,
          payment_done_off_platform,
          history
        }) => {
          let host = {
            info: this.formatArtist(hostArtist),
            isHost: true,
            songHost: true,
            isMe: this.isMe(hostArtist),
            hostActive: this.isMe(hostArtist),
            category: null
          };

          let isPartyCompany = null;
          let myDeal = null;

          if (this.isMe(hostArtist)) isPartyCompany = is_host_company;

          const details = { id, name, purchased: !!purchases.length };
          const { split_details = [] } = splits[0] || {};

          const guests = split_details.map(el => {
            const artist =
              el.deal.artistFrom.id === hostArtist.id
                ? el.deal.artistTo
                : el.deal.artistFrom;

            if (this.isMe(artist)) {
              isPartyCompany = el.deal.is_guest_company;
              myDeal = el.deal.id;
            }

            return {
              status: el.deal.status,
              info: this.formatArtist(artist),
              services: el.deal.services || [],
              fee_status: el.deal.fee_status,
              fee: el.deal.fee,
              percentage: el.percentage,
              deal_id: el.deal_id,
              isMe: this.isMe(artist),
              category: this.getCategory(
                this.isMe(hostArtist),
                this.isMe(artist),
                el.deal.status
              ),
              hostActive: this.isMe(el.deal.artistFrom),
              songHost: false,
              chargesEnabled: this.checkChargesEnabled(artist),
              inducement: this.getInducementLetter(
                el.deal.contracts[0],
                this.isMe(el.deal.artistFrom) ? "host" : "guest",
                {
                  host: el.deal.artistFrom.artist_users[0]?.user_id,
                  guest: el.deal.artistTo.artist_users[0]?.user_id
                },
                {
                  host: el.deal.artistFrom.name,
                  guest: el.deal.artistTo.name
                },
                isPartyCompany
              ),
              recoupableFee: this.getRecoupableFee(el.deal.contracts[0] || {})
            };
          });

          const paid_by = purchases.length
            ? { ...purchases[0].paid_by, paid_at: purchases[0].created_at }
            : null;

          host.percentage = this.getHostPercentage(guests);
          return {
            ...details,
            isPartyCompany,
            myDeal,
            host_id: hostArtist.id,
            collaborators: [host, ...guests],
            ...this.getViewerInfo([host, ...guests]),
            contacts,
            paid_by,
            payment_done_off_platform,
            history
          };
        }
      );
    },
    closeModal(refetch) {
      if (refetch) this.$apollo.queries.results.refetch();
      this.showModal = false;
    }
  },
  apollo: {
    results: {
      query: GET_SONGS,
      variables() {
        return { artistId: this.$store.getters["account/getArtistId"] };
      },
      update({ artists, songs }) {
        return {
          artists,
          songs: this.formatSongs(songs)
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.container {
  width: 100%;
  height: 100%;

  .content {
    display: grid;
    grid-template-columns: 400px 1fr;
    overflow: hidden;

    .left-panel {
      position: relative;

      .filters {
        display: flex;
        padding: 20px 30px;

        .search {
          flex: 1;
          display: flex;
          align-items: center;

          .icon {
            img {
              width: 20px;
              height: 20px;
            }
          }

          input {
            flex: 1;
            margin-left: 8px;
            border: none;
            outline: none;
            color: $text-additional;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.22px;
          }
        }
      }

      .songs-container {
        overflow-x: hidden;
        padding: 0 30px 0;
        // height: calc(100vh - 120px);
        height: calc(100vh - 250px);
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: $accent rgba(#cdcccc, 0.3);

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          @include background-opacity(#cdcccc, 0.3);
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $accent;
          border-radius: 5px;
        }

        .cards-wrapper {
          .cards {
            .category {
              margin-bottom: 20px;
              border: 2px solid $gray;
              border-radius: 10px;
              padding: 10px;

              .category-title {
                font-weight: 800;
                font-size: 1.1rem;
                margin-bottom: 10px;
              }

              .category-cleared {
                font-style: italic;
              }

              .card {
                padding: 8px 0;
                margin-bottom: 10px;
                background-color: $gray;
                border: 2px solid $gray;
                border-radius: 10px;
                cursor: pointer;

                &.active,
                &:hover {
                  background-color: $gray;
                  border: 2px solid #b6b6b6;
                  box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
                  border-radius: 10px;
                }

                .top {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  padding: 0 16px;

                  .title {
                    font-size: 16px;
                  }

                  .party-icon {
                    background-color: black;
                    color: white;
                    font-size: 15px;
                    height: 22px;
                    width: 22px;
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 2px;
                  }
                }

                .collaborators {
                  width: 100%;
                  padding: 8px 14px;
                  display: flex;

                  .collaborator {
                    img {
                      margin: 0 2px;
                      height: 34px;
                      width: 34px;
                      border-radius: 50%;
                      border: 1px $accent solid;
                    }

                    .initials {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 34px;
                      width: 34px;
                      font-family: sans-serif;
                      font-size: 14px;
                      border-radius: 50%;
                      background-color: $black;
                      border: 1px solid $accent;
                      color: $white;
                      margin: 0 2px;
                    }
                  }
                }

                .divider {
                  height: 2px;
                  width: 100%;
                  background-color: $white;
                }

                .my-rpm {
                  padding: 8px 16px 0;
                  font-size: 0.9rem;

                  button {
                    font-family: "Roboto Mono";
                    border-radius: 50%;
                    border: 1px $white solid;
                    height: 1.5rem;
                    width: 1.5rem;
                    line-height: 0;
                    background-color: $white;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    margin-right: 0.3rem;

                    &:hover,
                    &:active,
                    &:focus {
                      outline: none;
                      cursor: pointer;
                    }
                  }

                  b {
                    font-family: "Roboto Mono";
                    margin-left: 0.3rem;
                  }
                }
              }

              .selected-card {
                border: 2px solid rgba(255, 3, 90, 0.5) !important;
                box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
                border-radius: 10px;
              }

              .action-needed {
                border: 2px dashed rgba(255, 3, 90, 0.5) !important;
                box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
                border-radius: 10px;
              }
            }
          }
        }
      }

      .add-song {
        position: absolute;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        bottom: 0;
        padding: 10px 0;
        width: 100%;
        display: flex;
        justify-content: center;

        button {
          display: flex;
          padding: 0.75rem 2.5rem;

          img {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
