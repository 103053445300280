<template>
  <div class="px-4 py-4">
    <nav class="flex justify-center w-full" aria-label="Progress">
      <ol role="list" class="w-full space-y-2">
        <template v-for="(step, i) in stepsToDisplay">
          <li
            v-if="step && step.action_by == artist_id"
            class="cursor-default select-none"
            :key="step.id"
          >
            <div class="cursor-default group">
              <span
                style="width: fit-content;"
                class="flex items-center"
                v-popover.top="{ name: step.id, event: 'hover', pointer: true }"
              >
                <span
                  class="relative flex items-center justify-center flex-shrink-0 w-5 h-5 text-nvoko"
                >
                  <Icon :name="step.icon" />
                </span>
                <span class="ml-2 text-sm font-medium text-gray-900">
                  {{ step.description }}
                </span>
              </span>
            </div>
            <popover :name="step.id" :pointer="true" :event="'hover'">
              <span>
                <span class="font-bold text-nvoko">
                  {{ step.created_at | date }}
                </span>
              </span>
            </popover>
          </li>
        </template>
      </ol>
    </nav>
  </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
  components: {
    Icon
  },
  props: {
    history: Array,
    artist_id: String
  },
  filters: {
    date(timestamp) {
      return new Date(timestamp).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric"
      });
    }
  },

  methods: {
    action(action) {
      // alert(action);
    }
  },
  computed: {
    stepsToDisplay() {
      console.log(this.history);
      return [
        this.history.filter(step => step.action_by === this.artist_id).pop()
      ];
    }
  }
};
</script>

<style>
.vue-popover {
  width: fit-content !important;
}
</style>
