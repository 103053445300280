<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <!-- <Loader v-if="$apollo.queries.contract.loading" color="#FF035A" size="50" /> -->
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <div class="title">
                <h3>Connect to Release</h3>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="w-full pb-2">
                <div
                  class=" px-4 py-2 border-2 border-gray-100 rounded-full search"
                >
                  <div class="icon">
                    <img src="../../assets/icons/Search.svg" />
                  </div>
                  <input
                    type="search"
                    @keyup.enter="submit"
                    v-model="searchQuery"
                    placeholder="Search by name"
                  />
                </div>
              </div>

              <div class="details">
                <!-- <button @click="submit" class="primary">Search</button> -->

                <div v-if="loading" class="loader">
                  <Loader color="#FF035A" size="50" />
                </div>

                <div v-else-if="searchResults.length" class="space-y-2 pt-4">
                  <div
                    v-for="result in searchResults"
                    :key="result.id"
                    class="flex items-center bg-white rounded-lg border border-gray-100 p-4"
                  >
                    <img
                      :src="result.artwork"
                      alt="Album artwork"
                      class="w-16 h-16 object-cover rounded-md mr-4"
                    />
                    <div class="flex-grow">
                      <h4 class="text-lg font-semibold">{{ result.title }}</h4>
                      <p class="text-sm text-gray-600">{{ result.artist }}</p>
                      <p v-if="result.isrc" class="text-xs text-gray-400">
                        ISRC: {{ result.isrc }}
                      </p>
                    </div>
                    <div class="flex items-center">
                      <span class="mr-4">
                        <svg
                          v-if="result.provider === 'spotify'"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-brand-spotify"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"
                          />
                          <path d="M8 11.973c2.5 -1.473 5.5 -.973 7.5 .527" />
                          <path d="M9 15c1.5 -1 4 -1 5 .5" />
                          <path d="M7 9c2 -1 6 -2 10 .5" />
                        </svg>
                        <svg
                          v-else-if="result.provider === 'apple music'"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-brand-apple"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M8.286 7.008c-3.216 0 -4.286 3.23 -4.286 5.92c0 3.229 2.143 8.072 4.286 8.072c1.165 -.05 1.799 -.538 3.214 -.538c1.406 0 1.607 .538 3.214 .538s4.286 -3.229 4.286 -5.381c-.03 -.011 -2.649 -.434 -2.679 -3.23c-.02 -2.335 2.589 -3.179 2.679 -3.228c-1.096 -1.606 -3.162 -2.113 -3.75 -2.153c-1.535 -.12 -3.032 1.077 -3.75 1.077c-.729 0 -2.036 -1.077 -3.214 -1.077z"
                          />
                          <path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" />
                        </svg>
                        <svg
                          v-else-if="result.provider === 'deezer'"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-brand-deezer"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 16.5h2v.5h-2z" />
                          <path d="M8 16.5h2.5v.5h-2.5z" />
                          <path d="M16 17h-2.5v-.5h2.5z" />
                          <path d="M21.5 17h-2.5v-.5h2.5z" />
                          <path d="M21.5 13h-2.5v.5h2.5z" />
                          <path d="M21.5 9.5h-2.5v.5h2.5z" />
                          <path d="M21.5 6h-2.5v.5h2.5z" />
                          <path d="M16 13h-2.5v.5h2.5z" />
                          <path d="M8 13.5h2.5v-.5h-2.5z" />
                          <path d="M8 9.5h2.5v.5h-2.5z" />
                        </svg>

                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-disc"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"
                          />
                          <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                          <path d="M7 12a5 5 0 0 1 5 -5" />
                          <path d="M12 17a5 5 0 0 0 5 -5" />
                        </svg>
                      </span>
                      <div
                        @click.prevent="selectSong(result)"
                        class="cursor-pointer p-1 rounded-lg hover:bg-gray-100 transition-all"
                      >
                        <svg
                          v-if="
                            !releases.some(
                              release => release.release_id === result.id
                            )
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          class="text-green-500"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 15l6 -6" />
                          <path
                            d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"
                          />
                          <path
                            d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463"
                          />
                        </svg>
                        <svg
                          v-else
                          xmlns="http://www.w3.org/2000/svg"
                          class="text-nvoko"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 15l3 -3m2 -2l1 -1" />
                          <path
                            d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"
                          />
                          <path d="M3 3l18 18" />
                          <path
                            d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <p v-else-if="searched">No results found</p>
              </div>
              <!-- <div class="buttons">

                <button @click="$emit('close')" class="primary outline">
                  Close
                </button>
              </div> -->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { jsPDF } from "jspdf";
import gql from "graphql-tag";

import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import { searchAllProviders } from "@/services/musicApiServices";
import Loader from "@/components/Loader.vue";
const TOGGLE_EXTERNAL_RELEASE = gql`
  mutation InsertRelease(
    $type: String!
    $songId: uuid!
    $releaseId: String!
    $isrc: String
  ) {
    insert_song_release(
      objects: [
        { song_id: $songId, release_id: $releaseId, isrc: $isrc, type: $type }
      ]
    ) {
      returning {
        id
        type
        release_id
        song_id
        isrc
      }
    }
  }
`;
export default {
  name: "ConnectToRelease",
  components: {
    ModalCloseIcon,
    Loader
  },
  props: {
    song: Object
  },
  data() {
    return {
      searchQuery: "",
      searchResults: [],
      loading: false,
      searched: false,
      initial: true,
      releases: []
    };
  },

  apollo: {},
  computed: {
    artist() {
      return this.$store.getters["account/getArtist"];
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.searched = false;
      const q = this.initial
        ? `${this.searchQuery} ${this.artist.name}`
        : this.searchQuery;
      searchAllProviders(q)
        .then(res => {
          this.searchResults = res.flat();
          this.searched = true;
        })
        .finally(() => {
          this.loading = false;
          this.initial = false;
        });
      // this.$emit('close');
    },
    selectSong(song) {
      const existingRelease = this.releases.find(
        release => release.release_id === song.id
      );

      let mutation;
      let variables;

      if (existingRelease) {
        mutation = gql`
          mutation DeleteRelease($id: uuid!) {
            delete_song_release(where: { id: { _eq: $id } }) {
              affected_rows
            }
          }
        `;
        variables = { id: existingRelease.id };
      } else {
        mutation = gql`
          mutation InsertRelease(
            $songId: uuid!
            $releaseId: String!
            $isrc: String
            $type: String!
          ) {
            insert_song_release(
              objects: [
                {
                  song_id: $songId
                  release_id: $releaseId
                  isrc: $isrc
                  type: $type
                }
              ]
            ) {
              returning {
                id
                type
                release_id
                song_id
                isrc
              }
            }
          }
        `;
        variables = {
          songId: this.song.id,
          releaseId: song.id,
          isrc: song.isrc,
          type: song.provider
        };
      }

      this.$apollo
        .mutate({
          mutation,
          variables
        })
        .then(response => {
          if (existingRelease) {
            const index = this.releases.findIndex(
              release => release.id === existingRelease.id
            );
            if (index > -1) {
              this.releases.splice(index, 1);
            }
          } else {
            this.releases.push(response.data.insert_song_release.returning[0]);
          }
          // Update the local state without emitting an event or reloading
          this.$forceUpdate();
        })
        .catch(error => {
          console.error("Error toggling release:", error);
        });
    }
  },
  mounted() {
    this.releases = this.song.external_releases || [];
    this.searchQuery = `${this.song.name}`;
    this.submit();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  width: 600px;
  min-height: 40vh;
  max-height: 80vh;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1.5rem 32px;

  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .title {
    display: flex;
    width: 100%;
    justify-content: center;

    .avatar {
      width: 123px;
      height: 123px;
      overflow: hidden;
      border-radius: 15px;

      img {
        object-fit: cover;
      }
    }

    h2 {
      margin-left: 32px;
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: -0.666667px;
      color: $text;

      span {
        font-weight: 700;
      }
    }
  }
}

.modal-body {
  width: 100%;
  height: calc(100% - 158px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
  padding-top: 0;

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }

    .text {
      ::v-deep p {
        padding: 10px 0;
      }

      ::v-deep h1 {
        font-size: 2em;
      }

      ::v-deep h2 {
        font-size: 1.5em;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    margin-bottom: -2.5rem;

    button {
      width: 136px;
      height: 44px;
      margin: 0 16px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>
